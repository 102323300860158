
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import moment from 'moment';
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
//   IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button,
//   TextField, Snackbar, Avatar, Box, Typography
// } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { useDropzone } from 'react-dropzone';
// import MuiAlert from '@mui/material/Alert';
// import AddIcon from '@mui/icons-material/Add';

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// const NewsTable = () => {
//   const [news, setNews] = useState([]);
//   const [editNews, setEditNews] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const API_URL = process.env.REACT_APP_API_URL;
//   const token = localStorage.getItem('token');

//   useEffect(() => {
//     const fetchNews = async () => {
//       try {
//         const response = await axios.get(`${API_URL}/api/news`, {
//           headers: {
//             'x-access-token': token,
//             'Content-Type': 'application/json'
//           }
//         });
//         setNews(response.data);
//       } catch (error) {
//         console.error('There was an error fetching the news!', error);
//         setSnackbarMessage('Có lỗi xảy ra khi lấy dữ liệu tin tức');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       }
//     };

//     fetchNews();
//   }, [API_URL, token]);

//   const handleEdit = (article) => {
//     setEditNews({ ...article, published_at: moment(article.published_at).format('YYYY-MM-DDTHH:mm') });
//     setOpen(true);
//   };

//   const handleCreate = () => {
//     setEditNews({ title: '', short_content: '', full_content: '', published_at: '', image_url: '' });
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setEditNews(null);
//     setSelectedFile(null);
//   };

//   const handleSave = () => {
//     const formData = new FormData();
//     formData.append('title', editNews.title);
//     formData.append('short_content', editNews.short_content);
//     formData.append('full_content', editNews.full_content);
//     formData.append('published_at', moment(editNews.published_at).format());
//     if (selectedFile) {
//       formData.append('image', selectedFile);
//     }

//     const request = editNews.id
//       ? axios.put(`${API_URL}/news/${editNews.id}`, formData, {
//           headers: {
//             'x-access-token': token,
//             'Content-Type': 'multipart/form-data'
//           }
//         })
//       : axios.post(`${API_URL}/news`, formData, {
//           headers: {
//             'x-access-token': token,
//             'Content-Type': 'multipart/form-data'
//           }
//         });

//     request
//       .then((response) => {
//         const updatedArticle = {
//           ...editNews,
//           id: response.data.id,
//           image_url: response.data.imageUrl ? response.data.imageUrl.split('/').pop() : editNews.image_url
//         };
//         setNews(editNews.id ? news.map(article => (article.id === editNews.id ? updatedArticle : article)) : [...news, updatedArticle]);
//         handleClose();
//         setSnackbarMessage('Bạn đã lưu dữ liệu thành công');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       })
//       .catch(error => {
//         console.error('There was an error updating the article!', error);
//         setSnackbarMessage('Có lỗi xảy ra khi lưu dữ liệu');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       });
//   };

//   const handleDelete = (articleId) => {
//     axios.delete(`${API_URL}/news/${articleId}`, {
//       headers: {
//         'x-access-token': token,
//         'Content-Type': 'application/json'
//       }
//     })
//       .then(() => {
//         setNews(news.filter(article => article.id !== articleId));
//         setSnackbarMessage('Bài viết đã được xóa');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       })
//       .catch(error => {
//         console.error('There was an error deleting the article!', error);
//         setSnackbarMessage('Có lỗi xảy ra khi xóa bài viết');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       });
//   };

//   const handleChange = (e) => {
//     setEditNews({ ...editNews, [e.target.name]: e.target.value });
//   };

//   const handleEditorChange = (event, editor) => {
//     const data = editor.getData();
//     setEditNews({ ...editNews, full_content: data });
//   };

//   const onDrop = (acceptedFiles) => {
//     setSelectedFile(acceptedFiles[0]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop });

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <Box>
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt:10, mr:5, ml:5}}>
//         <Typography variant="h5">Quản lý tin tức</Typography>
//         <Button
//           variant="contained"
//           color="secondary"
//           startIcon={<AddIcon />}
//           onClick={handleCreate}
//           sx={{
//             backgroundColor: '#e91e63',
//             '&:hover': {
//               backgroundColor: '#d81b60',
//             },
//           }}
//         >
//           Tạo bài viết mới
//         </Button>
//       </Box>
//       <Box sx={{  m:5}}>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>ID</TableCell>
//               <TableCell>Tiêu đề</TableCell>
//               <TableCell>Nội dung ngắn</TableCell>
//               <TableCell>Ngày xuất bản</TableCell>
//               <TableCell>Hành động</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {news.map((article) => (
//               <TableRow key={article.id}>
//                 <TableCell>{article.id}</TableCell>
//                 <TableCell>{article.title}</TableCell>
//                 <TableCell>{article.short_content}</TableCell>
//                 <TableCell>{moment(article.published_at).format('DD-MM-YYYY HH:mm')}</TableCell>
//                 <TableCell>
//                   <IconButton
//                     color="primary"
//                     onClick={() => handleEdit(article)}
//                     sx={{
//                       border: '1px solid #1976d2',
//                       borderRadius: '4px',
//                       backgroundColor: '#1976d2',
//                       color: '#ffffff',
//                       marginRight: '8px',
//                       '&:hover': {
//                         backgroundColor: '#1565c0',
//                         borderColor: '#1565c0',
//                       },
//                     }}
//                   >
//                     <EditIcon />
//                   </IconButton>
//                   <IconButton
//                     color="secondary"
//                     onClick={() => handleDelete(article.id)}
//                     sx={{
//                       border: '1px solid #d32f2f',
//                       borderRadius: '4px',
//                       backgroundColor: '#d32f2f',
//                       color: '#ffffff',
//                       '&:hover': {
//                         backgroundColor: '#c62828',
//                         borderColor: '#c62828',
//                       },
//                     }}
//                   >
//                     <DeleteIcon />
//                   </IconButton>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
// </Box>
//       <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
//         <DialogTitle>{editNews?.id ? 'Sửa bài viết' : 'Tạo bài viết mới'}</DialogTitle>
//         <DialogContent>
//           <TextField
//             margin="dense"
//             label="Tiêu đề"
//             type="text"
//             fullWidth
//             name="title"
//             value={editNews?.title || ''}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             label="Nội dung ngắn"
//             type="text"
//             fullWidth
//             name="short_content"
//             multiline
//             minRows={3}
//             value={editNews?.short_content || ''}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             label="Ngày xuất bản"
//             type="datetime-local"
//             fullWidth
//             name="published_at"
//             value={editNews?.published_at || ''}
//             onChange={handleChange}
//           />
//           {editNews?.image_url && (
//             <div>
//               <img src={`${API_URL}/uploads/${editNews.image_url}`} alt="thumbnail" style={{ width: '300px', height: '180px' }} />
//             </div>
//           )}
//           <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center', marginTop: '10px' }}>
//             <input {...getInputProps()} />
//             <p>Chọn tệp hoặc kéo thả tệp vào đây</p>
//             {selectedFile && <p>{selectedFile.name}</p>}
//           </div>
//           <CKEditor
//             editor={ClassicEditor}
//             data={editNews?.full_content || ''}
//             onChange={handleEditorChange}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="secondary" variant="outlined">
//             Hủy
//           </Button>
//           <Button onClick={handleSave} color="primary" variant="contained">
//             Lưu
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//       >
//         <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default NewsTable;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button,
  TextField, Snackbar, Box, Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDropzone } from 'react-dropzone';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewsTable = () => {
  const [news, setNews] = useState([]);
  const [editNews, setEditNews] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteArticleId, setDeleteArticleId] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/news`, {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
          }
        });
        setNews(response.data);
      } catch (error) {
        console.error('There was an error fetching the news!', error);
        setSnackbarMessage('Có lỗi xảy ra khi lấy dữ liệu tin tức');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchNews();
  }, [API_URL, token]);

  const handleEdit = (article) => {
    setEditNews({ ...article, published_at: moment(article.published_at).format('YYYY-MM-DDTHH:mm') });
    setOpen(true);
  };

  const handleCreate = () => {
    const currentTime = moment().format('YYYY-MM-DDTHH:mm');
    setEditNews({ title: '', short_content: '', full_content: '', published_at: currentTime, image_url: '' });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditNews(null);
    setSelectedFile(null);
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append('title', editNews.title);
    formData.append('short_content', editNews.short_content);
    formData.append('full_content', editNews.full_content);
    formData.append('published_at', moment(editNews.published_at).format());
    if (selectedFile) {
      formData.append('image', selectedFile);
    }

    const request = editNews.id
      ? axios.put(`${API_URL}/news/${editNews.id}`, formData, {
          headers: {
            'x-access-token': token,
            'Content-Type': 'multipart/form-data'
          }
        })
      : axios.post(`${API_URL}/news`, formData, {
          headers: {
            'x-access-token': token,
            'Content-Type': 'multipart/form-data'
          }
        });

    request
      .then((response) => {
        const updatedArticle = {
          ...editNews,
          id: response.data.id,
          image_url: response.data.imageUrl ? response.data.imageUrl.split('/').pop() : editNews.image_url
        };
        setNews(editNews.id ? news.map(article => (article.id === editNews.id ? updatedArticle : article)) : [...news, updatedArticle]);
        handleClose();
        setSnackbarMessage('Bạn đã lưu dữ liệu thành công');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(error => {
        console.error('There was an error updating the article!', error);
        setSnackbarMessage('Có lỗi xảy ra khi lưu dữ liệu');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleDeleteConfirmOpen = (articleId) => {
    setDeleteArticleId(articleId);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
    setDeleteArticleId(null);
  };

  const handleDelete = () => {
    axios.delete(`${API_URL}/news/${deleteArticleId}`, {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    })
      .then(() => {
        setNews(news.filter(article => article.id !== deleteArticleId));
        setSnackbarMessage('Bài viết đã được xóa');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        handleDeleteConfirmClose();
      })
      .catch(error => {
        console.error('There was an error deleting the article!', error);
        setSnackbarMessage('Có lỗi xảy ra khi xóa bài viết');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleChange = (e) => {
    setEditNews({ ...editNews, [e.target.name]: e.target.value });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditNews({ ...editNews, full_content: data });
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 10, mr: 5, ml: 5 }}>
        <Typography variant="h5">Quản lý tin tức</Typography>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
          sx={{
            backgroundColor: '#e91e63',
            '&:hover': {
              backgroundColor: '#d81b60',
            },
          }}
        >
          Tạo bài viết mới
        </Button>
      </Box>
      <Box sx={{ m: 5 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Tiêu đề</TableCell>
                <TableCell>Nội dung ngắn</TableCell>
                <TableCell>Ngày xuất bản</TableCell>
                <TableCell>Hành động</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {news.map((article) => (
                <TableRow key={article.id}>
                  <TableCell>{article.id}</TableCell>
                  <TableCell>{article.title}</TableCell>
                  <TableCell>{article.short_content}</TableCell>
                  <TableCell>{moment(article.published_at).format('DD-MM-YYYY HH:mm')}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(article)}
                      sx={{
                        border: '1px solid #1976d2',
                        borderRadius: '4px',
                        backgroundColor: '#1976d2',
                        color: '#ffffff',
                        marginRight: '8px',
                        '&:hover': {
                          backgroundColor: '#1565c0',
                          borderColor: '#1565c0',
                        },
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteConfirmOpen(article.id)}
                      sx={{
                        border: '1px solid #d32f2f',
                        borderRadius: '4px',
                        backgroundColor: '#d32f2f',
                        color: '#ffffff',
                        '&:hover': {
                          backgroundColor: '#c62828',
                          borderColor: '#c62828',
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{editNews?.id ? 'Sửa bài viết' : 'Tạo bài viết mới'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Tiêu đề"
            type="text"
            fullWidth
            name="title"
            value={editNews?.title || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Nội dung ngắn"
            type="text"
            fullWidth
            name="short_content"
            multiline
            minRows={3}
            value={editNews?.short_content || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Ngày xuất bản"
            type="datetime-local"
            fullWidth
            name="published_at"
            value={editNews?.published_at || ''}
            onChange={handleChange}
          />
          {editNews?.image_url && (
            <div>
              <img src={`${API_URL}/uploads/${editNews.image_url}`} alt="thumbnail" style={{ width: '300px', height: '180px' }} />
            </div>
          )}
          <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center', marginTop: '10px' }}>
            <input {...getInputProps()} />
            <p>Chọn tệp hoặc kéo thả tệp vào đây</p>
            {selectedFile && <p>{selectedFile.name}</p>}
          </div>
          <CKEditor
            editor={ClassicEditor}
            data={editNews?.full_content || ''}
            onChange={handleEditorChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Hủy
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Lưu
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteConfirmOpen} onClose={handleDeleteConfirmClose}>
        <DialogTitle>Xác nhận xóa</DialogTitle>
        <DialogContent>
          <Typography>Bạn có chắc chắn muốn xóa bài viết này?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmClose} color="secondary" variant="outlined">
            Hủy
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            Xóa
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewsTable;

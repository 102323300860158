import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DescriptionIcon from '@mui/icons-material/Description';
import axios from 'axios';

const Statistics = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [stats, setStats] = useState({
    members: 0,
    appointments: 0,
    services: 0,
    posts: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/dashboard-stats`, {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
          },
        });
        setStats(response.data);
      } catch (error) {
        console.error('There was an error fetching the dashboard statistics!', error);
      }
    };

    fetchData();
  }, [API_URL]);

  const renderCard = (title, value, icon, bgColor) => (
    <Grid item xs={12} md={3}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: bgColor, color: 'white' }}>
        {icon}
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h4">{value}</Typography>
      </Paper>
    </Grid>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 4 }}>
      {/* <Typography variant="h4" gutterBottom>Admin Dashboard</Typography> */}
      <Grid container spacing={3}>
        {renderCard('Khách hàng', stats.members, <PeopleIcon sx={{ fontSize: 40, mb: 2 }} />, '#1e88e5')}
        {renderCard('Đặt lịch khám', stats.appointments, <EventIcon sx={{ fontSize: 40, mb: 2 }} />, '#ffa726')}
        {renderCard('Dịch vụ', stats.services, <LocalHospitalIcon sx={{ fontSize: 40, mb: 2 }} />, '#3949ab')}
        {renderCard('Tin tức', stats.posts, <DescriptionIcon sx={{ fontSize: 40, mb: 2 }} />, '#d32f2f')}
      </Grid>
    </Container>
  );
};

export default Statistics;

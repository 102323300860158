
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Button, TextField, Container, Typography, Box, Snackbar, Alert, InputAdornment, IconButton } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import axios from 'axios';

// const LoginPage = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const navigate = useNavigate();
//   const API_URL = process.env.REACT_APP_API_URL;

//   const handleLogin = (event) => {
//     event.preventDefault();
//     if (!username || !password) {
//       setSnackbarMessage('Bạn cần điền thông tin đăng nhập!');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     axios.post(`${API_URL}/login`, { username, password })
//       .then(response => {
//         if (response.data.token) {
//           localStorage.setItem('token', response.data.token);
//           navigate('/dashboard');
//         } else {
//           setSnackbarMessage(response.data.message);
//           setSnackbarSeverity('error');
//           setSnackbarOpen(true);
//         }
//       })
//       .catch(error => {
//         console.error('There was an error logging in!', error);
//         setSnackbarMessage('Invalid username or password');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       });
//   };

//   const handleClickShowPassword = () => setShowPassword((show) => !show);

//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <Container
//       maxWidth={false}
//       disableGutters
//       sx={{
//         background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
//         minHeight: '100vh',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         padding: 0,
//         margin: 0
//       }}
//     >
//       <Box
//         sx={{
//           background: 'white',
//           padding: 3,
//           borderRadius: 2,
//           boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//           textAlign: 'center',
//           width: '100%',
//           maxWidth: 400
//         }}
//       >
//         <img src="/logo.png" alt="Logo" style={{ marginBottom: 20, width: 100, height: 'auto' }} />
//         <Typography variant="h5" gutterBottom>
//           SẢN PHỤ KHOA SHE CLINIC! 👋
//         </Typography>
//         <Typography variant="body2" gutterBottom>
//           TRANG QUẢN TRỊ APP MINI ZALO 
//         </Typography>
//         <Box component="form" onSubmit={handleLogin} sx={{ mt: 3, width: '100%' }}>
//           <TextField
//             label="Tên đăng nhập"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//             required
//           />
//           <TextField
//             label="Mật khẩu"
//             type={showPassword ? 'text' : 'password'}
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton onClick={handleClickShowPassword} edge="end">
//                     {showPassword ? <VisibilityOff /> : <Visibility />}
//                   </IconButton>
//                 </InputAdornment>
//               ),
//             }}
//             required
//           />
//           <Button
//             type="submit"
//             variant="contained"
//             fullWidth
//             sx={{ mt: 3, mb: 2, backgroundColor: '#eb95bd', '&:hover': { backgroundColor: '#d084a8' } }}
//           >
//             Đăng nhập
//           </Button>
//         </Box>
//       </Box>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//       >
//         <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Container>
//   );
// };

// export default LoginPage;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Container, Typography, Box, Snackbar, Alert, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false); // State for loading indicator
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleLogin = (event) => {
    event.preventDefault();
    if (!username || !password) {
      setSnackbarMessage('Bạn cần điền thông tin đăng nhập!');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true); // Show loading indicator

    axios.post(`${API_URL}/login`, { username, password })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          setSnackbarMessage('Đăng nhập thành công!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setTimeout(() => {
            setLoading(false); // Hide loading indicator
            navigate('/dashboard');
          }, 2000); // Simulate a loading time of 2 seconds
        } else {
          setLoading(false); // Hide loading indicator
          setSnackbarMessage(response.data.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      })
      .catch(error => {
        console.error('There was an error logging in!', error);
        setLoading(false); // Hide loading indicator
        setSnackbarMessage('Invalid username or password');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        margin: 0
      }}
    >
      <Box
        sx={{
          background: 'white',
          padding: 3,
          borderRadius: 2,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
          width: '100%',
          maxWidth: 400,
          position: 'relative'
        }}
      >
        <img src="/logo.png" alt="Logo" style={{ marginBottom: 20, width: 80, height: 'auto' }} />
        <Typography variant="h5" gutterBottom>
          SẢN PHỤ KHOA SHE CLINIC! 👋
        </Typography>
        <Typography variant="body2" gutterBottom>
          TRANG QUẢN TRỊ APP MINI ZALO 
        </Typography>
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 3, width: '100%' }}>
          <TextField
            label="Tên đăng nhập"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="Mật khẩu"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3, mb: 2, backgroundColor: '#eb95bd', '&:hover': { backgroundColor: '#d084a8' } }}
            disabled={loading} // Disable the button when loading
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Đăng nhập'}
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LoginPage;

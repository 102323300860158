
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
//   IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Snackbar, Typography, Box
// } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { Search } from '@mui/icons-material';
// import MuiAlert from '@mui/material/Alert';
// import TableSortLabel from '@mui/material/TableSortLabel';
// import moment from 'moment';
// import { CSVLink } from 'react-csv';
// import * as XLSX from 'xlsx';
// import SaveAltIcon from '@mui/icons-material/SaveAlt'; // Import icon for Excel
// import ImportExportIcon from '@mui/icons-material/ImportExport'; // Import icon for CSV

// const UsersTable = () => {
//   const [users, setUsers] = useState([]);
//   const [editUser, setEditUser] = useState(null);
//   const [openEditDialog, setOpenEditDialog] = useState(false);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [userIdToDelete, setUserIdToDelete] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const [filter, setFilter] = useState('');
//   const [order, setOrder] = useState('asc');
//   const [orderBy, setOrderBy] = useState('name');
//   const API_URL = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     axios.get(`${API_URL}/users`)
//       .then(response => {
//         setUsers(response.data);
//       })
//       .catch(error => {
//         console.error('There was an error fetching the users!', error);
//       });
//   }, [API_URL]);

//   const handleEdit = (user) => {
//     setEditUser(user);
//     setOpenEditDialog(true);
//   };

//   const handleDelete = (userId) => {
//     setUserIdToDelete(userId);
//     setOpenDeleteDialog(true);
//   };

//   const handleConfirmDelete = () => {
//     axios.delete(`${API_URL}/users/${userIdToDelete}`)
//       .then(() => {
//         setUsers(users.filter(user => user.id !== userIdToDelete));
//         setOpenDeleteDialog(false);
//         setSnackbarMessage('User deleted successfully');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       })
//       .catch(error => {
//         console.error('There was an error deleting the user!', error);
//         setSnackbarMessage('Error deleting user');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       });
//   };

//   const handleEditSave = () => {
//     axios.put(`${API_URL}/users/${editUser.id}`, editUser)
//       .then(() => {
//         setUsers(users.map(user => (user.id === editUser.id ? editUser : user)));
//         setOpenEditDialog(false);
//         setSnackbarMessage('Cập nhật khách hàng thành công !');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       })
//       .catch(error => {
//         console.error('There was an error updating the user!', error);
//         setSnackbarMessage('Lỗi cập nhật khách hàng !');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       });
//   };

//   const handleEditChange = (event) => {
//     const { name, value } = event.target;
//     setEditUser({ ...editUser, [name]: value });
//   };

//   const handleFilterChange = (event) => {
//     setFilter(event.target.value);
//   };

//   const filteredUsers = users.filter(user =>
//     user.name.toLowerCase().includes(filter.toLowerCase()) ||
//     user.phone.includes(filter)
//   );

//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

//   const handleRequestSort = (property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const sortedUsers = filteredUsers.sort((a, b) => {
//     if (orderBy === 'birth_date') {
//       return order === 'asc'
//         ? new Date(a.birth_date) - new Date(b.birth_date)
//         : new Date(b.birth_date) - new Date(a.birth_date);
//     } else {
//       return order === 'asc'
//         ? a[orderBy].localeCompare(b[orderBy])
//         : b[orderBy].localeCompare(a[orderBy]);
//     }
//   });

//   const handleExportExcel = () => {
//     const ws = XLSX.utils.json_to_sheet(users);
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Users');
//     XLSX.writeFile(wb, 'users.xlsx');
//   };

//   return (
//     <TableContainer component={Paper}>
//       <Box sx={{  mt:3, mr:5, ml:5, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
//         <Typography variant="h5">Quản lý khách hàng</Typography>
//         <Box>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleExportExcel}
//             sx={{ mr: 1 }}
//             startIcon={<SaveAltIcon />}
//           >
//             Excel
//           </Button>
//           <CSVLink
//             data={users}
//             filename={"users.csv"}
//             className="btn btn-primary"
//             target="_blank"
//           >
//             <Button
//               variant="contained"
//               color="secondary"
//               startIcon={<ImportExportIcon />}
//             >
//               CSV
//             </Button>
//           </CSVLink>
//         </Box>
//       </Box>
//       <Box sx={{   mr:5, ml:5}}>
//         <TextField
//           variant="outlined"
//           label="Tìm kiếm"
//           fullWidth
//           margin="normal"
//           value={filter}
//           onChange={handleFilterChange}
//           InputProps={{
//             startAdornment: <Search />
//           }}
//         />
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell sortDirection={orderBy === 'id' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'id'}
//                   direction={orderBy === 'id' ? order : 'asc'}
//                   onClick={() => handleRequestSort('id')}
//                 >
//                   ID
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell sortDirection={orderBy === 'name' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'name'}
//                   direction={orderBy === 'name' ? order : 'asc'}
//                   onClick={() => handleRequestSort('name')}
//                 >
//                   Họ và tên
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell sortDirection={orderBy === 'phone' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'phone'}
//                   direction={orderBy === 'phone' ? order : 'asc'}
//                   onClick={() => handleRequestSort('phone')}
//                 >
//                   Số điện thoại
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell sortDirection={orderBy === 'address' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'address'}
//                   direction={orderBy === 'address' ? order : 'asc'}
//                   onClick={() => handleRequestSort('address')}
//                 >
//                   Địa chỉ
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell sortDirection={orderBy === 'birth_date' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'birth_date'}
//                   direction={orderBy === 'birth_date' ? order : 'asc'}
//                   onClick={() => handleRequestSort('birth_date')}
//                 >
//                   Ngày sinh
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell>Điểm thưởng</TableCell>
//               <TableCell>Hành động</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {sortedUsers.map((user) => (
//               <TableRow key={user.id}>
//                 <TableCell>{user.id}</TableCell>
//                 <TableCell>{user.name}</TableCell>
//                 <TableCell>{user.phone}</TableCell>
//                 <TableCell>{user.address}</TableCell>
//                 <TableCell>{moment(user.birth_date).format('DD-MM-YYYY')}</TableCell>
//                 <TableCell>{user.points}</TableCell>
//                 <TableCell>
//                   <IconButton
//                     onClick={() => handleEdit(user)}
//                     sx={{
//                       border: '1px solid #1976d2',
//                       borderRadius: '4px',
//                       backgroundColor: '#1976d2',
//                       color: '#ffffff',
//                       marginRight: '8px',
//                       '&:hover': {
//                         backgroundColor: '#1565c0',
//                         borderColor: '#1565c0',
//                       },
//                     }}
//                   >
//                     <EditIcon />
//                   </IconButton>
//                   <IconButton
//                     onClick={() => handleDelete(user.id)}
//                     sx={{
//                       border: '1px solid #d32f2f',
//                       borderRadius: '4px',
//                       backgroundColor: '#d32f2f',
//                       color: '#ffffff',
//                       '&:hover': {
//                         backgroundColor: '#c62828',
//                         borderColor: '#c62828',
//                       },
//                     }}
//                   >
//                     <DeleteIcon />
//                   </IconButton>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </Box>
//       {/* Edit Dialog */}
//       {editUser && (
//         <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
//           <DialogTitle>Chỉnh sửa thông tin</DialogTitle>
//           <DialogContent>
//             <TextField
//               margin="dense"
//               name="name"
//               label="Họ và tên"
//               type="text"
//               fullWidth
//               value={editUser.name}
//               onChange={handleEditChange}
//             />
//             <TextField
//               margin="dense"
//               name="phone"
//               label="Số điện thoại"
//               type="text"
//               fullWidth
//               value={editUser.phone}
//               onChange={handleEditChange}
//             />
//             <TextField
//               margin="dense"
//               name="address"
//               label="Địa chỉ"
//               type="text"
//               fullWidth
//               value={editUser.address}
//               onChange={handleEditChange}
//             />
//             <TextField
//               margin="dense"
//               name="birth_date"
//               label="Ngày sinh"
//               type="date"
//               fullWidth
//               value={moment(editUser.birth_date).format('YYYY-MM-DD')}
//               onChange={handleEditChange}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//             />
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setOpenEditDialog(false)} color="secondary" variant="outlined">
//               Hủy
//             </Button>
//             <Button onClick={handleEditSave} color="primary" variant="contained">
//               Lưu
//             </Button>
//           </DialogActions>
//         </Dialog>
//       )}

//       {/* Delete Confirmation Dialog */}
//       <Dialog
//         open={openDeleteDialog}
//         onClose={() => setOpenDeleteDialog(false)}
//       >
//         <DialogTitle>Xác nhận</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Bạn có chắc chắc xóa khách hàng này ?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDeleteDialog(false)} color="secondary" variant="outlined">
//             Hủy
//           </Button>
//           <Button onClick={handleConfirmDelete} color="primary" variant="contained">
//             Xóa
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//       >
//         <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} elevation={6} variant="filled">
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </TableContainer>
//   );
// };

// export default UsersTable;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Snackbar, Typography, Box, TablePagination
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Search } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import TableSortLabel from '@mui/material/TableSortLabel';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import SaveAltIcon from '@mui/icons-material/SaveAlt'; // Import icon for Excel
import ImportExportIcon from '@mui/icons-material/ImportExport'; // Import icon for CSV

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [filter, setFilter] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(`${API_URL}/users`)
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the users!', error);
      });
  }, [API_URL]);

  const handleEdit = (user) => {
    setEditUser(user);
    setOpenEditDialog(true);
  };

  const handleDelete = (userId) => {
    setUserIdToDelete(userId);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = () => {
    axios.delete(`${API_URL}/users/${userIdToDelete}`)
      .then(() => {
        setUsers(users.filter(user => user.id !== userIdToDelete));
        setOpenDeleteDialog(false);
        setSnackbarMessage('User deleted successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(error => {
        console.error('There was an error deleting the user!', error);
        setSnackbarMessage('Error deleting user');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleEditSave = () => {
    axios.put(`${API_URL}/users/${editUser.id}`, editUser)
      .then(() => {
        setUsers(users.map(user => (user.id === editUser.id ? editUser : user)));
        setOpenEditDialog(false);
        setSnackbarMessage('Cập nhật khách hàng thành công !');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(error => {
        console.error('There was an error updating the user!', error);
        setSnackbarMessage('Lỗi cập nhật khách hàng !');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditUser({ ...editUser, [name]: value });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');
    XLSX.writeFile(wb, 'users.xlsx');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(filter.toLowerCase()) ||
    user.phone.includes(filter)
  );

  const sortedUsers = filteredUsers.sort((a, b) => {
    if (orderBy === 'birth_date') {
      return order === 'asc'
        ? new Date(a.birth_date) - new Date(b.birth_date)
        : new Date(b.birth_date) - new Date(a.birth_date);
    } else {
      return order === 'asc'
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    }
  });

  return (
    <TableContainer component={Paper}>
      <Box sx={{ mt: 3, mr: 5, ml: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography variant="h5">Quản lý khách hàng</Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportExcel}
            sx={{ mr: 1 }}
            startIcon={<SaveAltIcon />}
          >
            Excel
          </Button>
          <CSVLink
            data={users}
            filename={"users.csv"}
            className="btn btn-primary"
            target="_blank"
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<ImportExportIcon />}
            >
              CSV
            </Button>
          </CSVLink>
        </Box>
      </Box>
      <Box sx={{ mr: 5, ml: 5}}>
        <TextField
          variant="outlined"
          label="Tìm kiếm"
          fullWidth
          margin="normal"
          value={filter}
          onChange={handleFilterChange}
          InputProps={{
            startAdornment: <Search />
          }}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'id' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={orderBy === 'id' ? order : 'asc'}
                  onClick={() => handleRequestSort('id')}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'name' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Họ và tên
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'phone' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'phone'}
                  direction={orderBy === 'phone' ? order : 'asc'}
                  onClick={() => handleRequestSort('phone')}
                >
                  Số điện thoại
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'address' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'address'}
                  direction={orderBy === 'address' ? order : 'asc'}
                  onClick={() => handleRequestSort('address')}
                >
                  Địa chỉ
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'birth_date' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'birth_date'}
                  direction={orderBy === 'birth_date' ? order : 'asc'}
                  onClick={() => handleRequestSort('birth_date')}
                >
                  Ngày sinh
                </TableSortLabel>
              </TableCell>
              <TableCell>Điểm thưởng</TableCell>
              <TableCell>Hành động</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>{user.address}</TableCell>
                <TableCell>{moment(user.birth_date).format('DD-MM-YYYY')}</TableCell>
                <TableCell>{user.points}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleEdit(user)}
                    sx={{
                      border: '1px solid #1976d2',
                      borderRadius: '4px',
                      backgroundColor: '#1976d2',
                      color: '#ffffff',
                      marginRight: '8px',
                      '&:hover': {
                        backgroundColor: '#1565c0',
                        borderColor: '#1565c0',
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(user.id)}
                    sx={{
                      border: '1px solid #d32f2f',
                      borderRadius: '4px',
                      backgroundColor: '#d32f2f',
                      color: '#ffffff',
                      '&:hover': {
                        backgroundColor: '#c62828',
                        borderColor: '#c62828',
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 8, 10, 25]}
        component="div"
        count={sortedUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* Edit Dialog */}
      {editUser && (
        <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
          <DialogTitle>Chỉnh sửa thông tin</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              name="name"
              label="Họ và tên"
              type="text"
              fullWidth
              value={editUser.name}
              onChange={handleEditChange}
            />
            <TextField
              margin="dense"
              name="phone"
              label="Số điện thoại"
              type="text"
              fullWidth
              value={editUser.phone}
              onChange={handleEditChange}
            />
            <TextField
              margin="dense"
              name="address"
              label="Địa chỉ"
              type="text"
              fullWidth
              value={editUser.address}
              onChange={handleEditChange}
            />
            <TextField
              margin="dense"
              name="birth_date"
              label="Ngày sinh"
              type="date"
              fullWidth
              value={moment(editUser.birth_date).format('YYYY-MM-DD')}
              onChange={handleEditChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEditDialog(false)} color="secondary" variant="outlined">
              Hủy
            </Button>
            <Button onClick={handleEditSave} color="primary" variant="contained">
              Lưu
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Xác nhận</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bạn có chắc chắc xóa khách hàng này ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary" variant="outlined">
            Hủy
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" variant="contained">
            Xóa
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} elevation={6} variant="filled">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </TableContainer>
  );
};

export default UsersTable;

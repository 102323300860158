
// import React, { useEffect, useState } from 'react';
// import { AppBar, Toolbar, Typography, Box, Avatar, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { AccountCircle,  Logout } from '@mui/icons-material';
// import axios from 'axios';

// const Topbar = () => {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [username, setUsername] = useState('');
//   const navigate = useNavigate();
//   const token = localStorage.getItem('token');
//   const API_URL = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     const fetchUserInfo = async () => {
//       try {
//         const response = await axios.get(`${API_URL}/me`, {
//           headers: {
//             'x-access-token': token,
//             'Content-Type': 'application/json'
//           }
//         });
//         setUsername(response.data.username);
//       } catch (error) {
//         console.error('Error fetching user info:', error);
//       }
//     };

//     fetchUserInfo();
//   }, [API_URL, token]);

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const handleLogout = () => {
//     // Xóa trạng thái đăng nhập
//     localStorage.removeItem('isLoggedIn');
//     // Điều hướng về trang login
//     navigate('/login');
//   };

//   return (
//     <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
//       <Toolbar>
//         <Typography variant="h6" noWrap component="div">
//           QUẢN LÝ ZALO MINI APP
//         </Typography>
//         <Box sx={{ flexGrow: 1 }} />
//         <Avatar alt="Admin" src="/logo.png" onClick={handleMenuOpen} sx={{ cursor: 'pointer' }} />
//         <Menu
//           anchorEl={anchorEl}
//           open={Boolean(anchorEl)}
//           onClose={handleMenuClose}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'right',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//           }}
//         >
//           <MenuItem>
//             <ListItemIcon>
//               <AccountCircle fontSize="small" />
//             </ListItemIcon>
//             <ListItemText primary={`Xin chào: ${username}`} />
//           </MenuItem>
         
//           <MenuItem onClick={handleLogout}>
//             <ListItemIcon>
//               <Logout fontSize="small" />
//             </ListItemIcon>
//             <ListItemText primary="Đăng xuất" />
//           </MenuItem>
//         </Menu>
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default Topbar;
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Avatar, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AccountCircle, Logout } from '@mui/icons-material';
import axios from 'axios';

const Topbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/me`, {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
          }
        });
        setUsername(response.data.username);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, [API_URL, token]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Xóa trạng thái đăng nhập
    localStorage.removeItem('isLoggedIn');
    // Điều hướng về trang login
    navigate('/login');
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#eb95bd' }}>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          QUẢN LÝ ZALO MINI APP
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Avatar alt="Admin" src="/logo.png" onClick={handleMenuOpen} sx={{ cursor: 'pointer' }} />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem>
            <ListItemIcon>
              <AccountCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={`Xin chào: ${username}`} />
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Đăng xuất" />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;

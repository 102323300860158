
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Dashboard from './pages/Dashboard';
// import ServicesTable from './components/ServicesTable';
// import AppointmentsTable from './components/AppointmentsTable';
// import Sidebar from './components/Sidebar'; // Đã bổ sung lại Sidebar
// import Topbar from './pages/Topbar'; // Bổ sung Topbar
// import ProtectedRoute from './components/ProtectedRoute';
// import LoginPage from './pages/LoginPage';
// import UpdateTokens from './components/UpdateTokens';
// import UsersTable from './components/UsersTable'; // Điều chỉnh import nếu cần
// import Statistics from './components/Statistics'; // Điều chỉnh import nếu cần
// import NewsTable from './components/NewsTable'; // Điều chỉnh import nếu cần
// import AdminTable from './components/AdminTable'; // Điều chỉnh import nếu cần
// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<LoginPage />} />
//         <Route path="/*" element={
//           <ProtectedRoute>
//             <div style={{ display: 'flex' }}>
//               <Sidebar /> {/* Đảm bảo Sidebar được hiển thị */}
//               <div style={{ flexGrow: 1 }}>
//                 <Topbar /> {/* Đảm bảo Topbar được hiển thị */}
                
//                 <div style={{ marginTop: '64px', width: '100%' }}>
//                   <Routes>
//                     <Route path="/dashboard" element={<Dashboard />} />
//                     <Route path="/Statistics" element={<Statistics />} />
//                     <Route path="/tintuc" element={<NewsTable />} />
//                     <Route path="/services" element={<ServicesTable />} />
//                     <Route path="/appointments" element={<AppointmentsTable />} />
//                     <Route path="/update-tokens" element={<UpdateTokens />} />
//                     <Route path="/users" element={<UsersTable />} />
//                     <Route path="/taikhoan" element={<AdminTable />} />
//                   </Routes>
//                   </div>
               
//               </div>
//             </div>
//           </ProtectedRoute>
//         } />
//       </Routes>
//     </Router>
//   );
// };

// export default App;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ServicesTable from './components/ServicesTable';
import AppointmentsTable from './components/AppointmentsTable';
import Sidebar from './components/Sidebar'; 
import Topbar from './pages/Topbar';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import UpdateTokens from './components/UpdateTokens';
import UsersTable from './components/UsersTable';
import Statistics from './components/Statistics';
import NewsTable from './components/NewsTable';
import AdminTable from './components/AdminTable';

const App = () => {
  return (
    <Router basename="/fe">
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/*" element={
          <ProtectedRoute>
            <div style={{ display: 'flex' }}>
              <Sidebar />
              <div style={{ flexGrow: 1 }}>
                <Topbar />
                <div style={{ marginTop: '64px', width: '100%' }}>
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/Statistics" element={<Statistics />} />
                    <Route path="/tintuc" element={<NewsTable />} />
                    <Route path="/services" element={<ServicesTable />} />
                    <Route path="/appointments" element={<AppointmentsTable />} />
                    <Route path="/update-tokens" element={<UpdateTokens />} />
                    <Route path="/users" element={<UsersTable />} />
                    <Route path="/taikhoan" element={<AdminTable />} />
                  </Routes>
                </div>
              </div>
            </div>
          </ProtectedRoute>
        } />
      </Routes>
    </Router>
  );
};

export default App;

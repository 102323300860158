
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip, TablePagination, TextField, Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, IconButton, Avatar, TableSortLabel } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import MuiAlert from '@mui/material/Alert';
// import moment from 'moment';

// const AppointmentsTable = () => {
//   const [appointments, setAppointments] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(8);
//   const [filter, setFilter] = useState('');
//   const [order, setOrder] = useState('asc');
//   const [orderBy, setOrderBy] = useState('name');
//   const [selectedAppointment, setSelectedAppointment] = useState(null);
//   const [openEditDialog, setOpenEditDialog] = useState(false);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [newStatus, setNewStatus] = useState('');
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const API_URL = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     axios.get(`${API_URL}/appointmentsall`)
//       .then(response => {
//         setAppointments(response.data);
//       })
//       .catch(error => {
//         console.error('There was an error fetching the appointments!', error);
//       });
//   }, [API_URL]);

//   if (!Array.isArray(appointments)) {
//     return <Typography variant="h6" color="error">Dữ liệu nhận từ API không hợp lệ</Typography>;
//   }

//   const renderStatus = (status) => {
//     let color;
//     switch (status) {
//       case 'pending':
//         color = 'warning';
//         break;
//       case 'active':
//         color = 'success';
//         break;
//       default:
//         color = 'default';
//     }
//     return <Chip label={status} color={color} />;
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleFilterChange = (event) => {
//     setFilter(event.target.value);
//   };

//   const handleEditClick = (appointment) => {
//     setSelectedAppointment(appointment);
//     setNewStatus(appointment.status);
//     setOpenEditDialog(true);
//   };

//   const handleDeleteClick = (appointment) => {
//     setSelectedAppointment(appointment);
//     setOpenDeleteDialog(true);
//   };

//   const handleStatusChange = (event) => {
//     setNewStatus(event.target.value);
//   };

//   const handleUpdateStatus = () => {
//     axios.put(`${API_URL}/appointmentsall/${selectedAppointment.id}/status`, { status: newStatus })
//       .then(response => {
//         setSnackbarMessage('Status and points updated successfully');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//         setOpenEditDialog(false);
//         setAppointments(appointments.map(appt => appt.id === selectedAppointment.id ? { ...appt, status: newStatus } : appt));
//       })
//       .catch(error => {
//         setSnackbarMessage('Error updating points');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         console.error('There was an error updating the points!', error);
//       });
//   };
//   const handleDeleteAppointment = () => {
//     axios.delete(`${API_URL}/appointmentsall/${selectedAppointment.id}`)
//       .then(response => {
//         setSnackbarMessage('Appointment deleted successfully');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//         setOpenDeleteDialog(false);
//         setAppointments(appointments.filter(appt => appt.id !== selectedAppointment.id));
//       })
//       .catch(error => {
//         setSnackbarMessage('Error deleting appointment');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         console.error('There was an error deleting the appointment!', error);
//       });
//   };

//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

//   const handleRequestSort = (property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const filteredAppointments = appointments.filter(appointment =>
//     appointment.name.toLowerCase().includes(filter.toLowerCase()) ||
//     appointment.phone.toLowerCase().includes(filter.toLowerCase())
//   );

//   const sortedAppointments = filteredAppointments.sort((a, b) => {
//     if (orderBy === 'birth_date' || orderBy === 'date' || orderBy === 'time') {
//       return order === 'asc'
//         ? new Date(a[orderBy]) - new Date(b[orderBy])
//         : new Date(b[orderBy]) - new Date(a[orderBy]);
//     } else {
//       return order === 'asc'
//         ? a[orderBy].localeCompare(b[orderBy])
//         : b[orderBy].localeCompare(a[orderBy]);
//     }
//   });

//   return (
//     <Paper style={{ width: '100%' }}>
//       <Box sx={{  mt:10, mr:5, ml:5}}>
//       <Typography variant="h5">Quản lý lịch khám bệnh</Typography>
//       </Box>
//       <Box display="flex" justifyContent="flex-end" p={2}>
//         <TextField
//           label="Tìm kiếm "
//           variant="outlined"
//           value={filter}
//           onChange={handleFilterChange}
//         />
//       </Box>
//       <Box sx={{   mr:5, ml:5}}>
//       <TableContainer sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
//         <Table sx={{ tableLayout: 'fixed' }}>
//           <TableHead>
//             <TableRow>
//               <TableCell sx={{ width: '3%' }} sortDirection={orderBy === 'id' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'id'}
//                   direction={orderBy === 'id' ? order : 'asc'}
//                   onClick={() => handleRequestSort('id')}
//                 >
//                   ID
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell sx={{ width: '15%' }} sortDirection={orderBy === 'name' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'name'}
//                   direction={orderBy === 'name' ? order : 'asc'}
//                   onClick={() => handleRequestSort('name')}
//                 >
//                   Họ và tên
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell sx={{ width: '10%' }} sortDirection={orderBy === 'phone' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'phone'}
//                   direction={orderBy === 'phone' ? order : 'asc'}
//                   onClick={() => handleRequestSort('phone')}
//                 >
//                   Số điện thoại
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell sx={{ width: '20%' }} sortDirection={orderBy === 'address' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'address'}
//                   direction={orderBy === 'address' ? order : 'asc'}
//                   onClick={() => handleRequestSort('address')}
//                 >
//                   Địa chỉ
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell sx={{ width: '10%' }} sortDirection={orderBy === 'birth_date' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'birth_date'}
//                   direction={orderBy === 'birth_date' ? order : 'asc'}
//                   onClick={() => handleRequestSort('birth_date')}
//                 >
//                   Ngày sinh
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell sx={{ width: '15%' }}>Dịch vụ</TableCell>
//               <TableCell sx={{ width: '3%' }} sortDirection={orderBy === 'time' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'time'}
//                   direction={orderBy === 'time' ? order : 'asc'}
//                   onClick={() => handleRequestSort('time')}
//                 >
//                   Giờ
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell sx={{ width: '8%' }} sortDirection={orderBy === 'date' ? order : false}>
//                 <TableSortLabel
//                   active={orderBy === 'date'}
//                   direction={orderBy === 'date' ? order : 'asc'}
//                   onClick={() => handleRequestSort('date')}
//                 >
//                   Ngày
//                 </TableSortLabel>
//               </TableCell>
//               <TableCell sx={{ width: '5%' }}>SL</TableCell>
//               <TableCell sx={{ width: '7%' }}>Trạng thái</TableCell>
//               <TableCell sx={{ width: '9%' }}>Hành động</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {sortedAppointments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((appointment) => (
//               <TableRow key={appointment.id}>
//                 <TableCell>{appointment.id}</TableCell>
//                 <TableCell>
//                   <Box display="flex" alignItems="center">
//                     <Avatar alt={appointment.name} src={appointment.avatar} sx={{ marginRight: 2 }} />
//                     {appointment.name}
//                   </Box>
//                 </TableCell>
//                 <TableCell>{appointment.phone}</TableCell>
//                 <TableCell>{appointment.address}</TableCell>
//                 <TableCell>{moment(appointment.birth_date).format('DD-MM-YYYY')}</TableCell>
//                 <TableCell>{appointment.service_name}</TableCell>
//                 <TableCell>{moment(appointment.time, 'HH:mm:ss').format('HH:mm')}</TableCell>
//                 <TableCell>{moment(appointment.date).format('DD-MM-YYYY')}</TableCell>
//                 <TableCell>{appointment.people_count}</TableCell>
//                 <TableCell>{renderStatus(appointment.status)}</TableCell>
//                 <TableCell>
//                   <IconButton
//                     color="primary"
//                     onClick={() => handleEditClick(appointment)}
//                     sx={{
//                       border: '1px solid #1976d2',
//                       borderRadius: '4px',
//                       backgroundColor: '#1976d2',
//                       color: '#ffffff',
//                       marginRight: '8px',
//                       '&:hover': {
//                         backgroundColor: '#1565c0',
//                         borderColor: '#1565c0',
//                       },
//                     }}
//                   >
//                     <EditIcon />
//                   </IconButton>
//                   <IconButton
//                     color="secondary"
//                     onClick={() => handleDeleteClick(appointment)}
//                     sx={{
//                       border: '1px solid #d32f2f',
//                       borderRadius: '4px',
//                       backgroundColor: '#d32f2f',
//                       color: '#ffffff',
//                       '&:hover': {
//                         backgroundColor: '#c62828',
//                         borderColor: '#c62828',
//                       },
//                     }}
//                   >
//                     <DeleteIcon />
//                   </IconButton>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <TablePagination
//         rowsPerPageOptions={[5, 8, 10, 25]}
//         component="div"
//         count={filteredAppointments.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       /></Box>
//       <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
//         <DialogTitle>Thay đổi trạng thái khám bệnh</DialogTitle>
//         <DialogContent>
//           <Select value={newStatus} onChange={handleStatusChange}>
//             <MenuItem value="pending">Pending</MenuItem>
//             <MenuItem value="active">Active</MenuItem>
//           </Select>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleUpdateStatus} color="primary">Lưu</Button>
//           <Button onClick={() => setOpenEditDialog(false)} color="secondary">Hủy</Button>
//         </DialogActions>
//       </Dialog>
//       <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
//         <DialogTitle>Xác nhận xóa</DialogTitle>
//         <DialogContent>
//           <Typography>Bạn có chắc chắn muốn xóa cuộc hẹn này không</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleDeleteAppointment} color="primary">Xóa</Button>
//           <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">Hủy</Button>
//         </DialogActions>
//       </Dialog>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//       >
//         <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} elevation={6} variant="filled">
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </Paper>
//   );
// };

// export default AppointmentsTable;
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip, TablePagination, TextField, Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, IconButton, Avatar, TableSortLabel } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import MuiAlert from '@mui/material/Alert';
// import moment from 'moment';
// import { CSVLink } from 'react-csv';
// import { jsPDF } from 'jspdf';
// import 'jspdf-autotable';
// import * as XLSX from 'xlsx';

// const AppointmentsTable = () => {
//   const [appointments, setAppointments] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(8);
//   const [filter, setFilter] = useState('');
//   const [order, setOrder] = useState('asc');
//   const [orderBy, setOrderBy] = useState('name');
//   const [selectedAppointment, setSelectedAppointment] = useState(null);
//   const [openEditDialog, setOpenEditDialog] = useState(false);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [newStatus, setNewStatus] = useState('');
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const API_URL = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     axios.get(`${API_URL}/appointmentsall`)
//       .then(response => {
//         setAppointments(response.data);
//       })
//       .catch(error => {
//         console.error('There was an error fetching the appointments!', error);
//       });
//   }, [API_URL]);

//   if (!Array.isArray(appointments)) {
//     return <Typography variant="h6" color="error">Dữ liệu nhận từ API không hợp lệ</Typography>;
//   }

//   const renderStatus = (status) => {
//     let color;
//     switch (status) {
//       case 'pending':
//         color = 'warning';
//         break;
//       case 'active':
//         color = 'success';
//         break;
//       default:
//         color = 'default';
//     }
//     return <Chip label={status} color={color} />;
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleFilterChange = (event) => {
//     setFilter(event.target.value);
//   };

//   const handleEditClick = (appointment) => {
//     setSelectedAppointment(appointment);
//     setNewStatus(appointment.status);
//     setOpenEditDialog(true);
//   };

//   const handleDeleteClick = (appointment) => {
//     setSelectedAppointment(appointment);
//     setOpenDeleteDialog(true);
//   };

//   const handleStatusChange = (event) => {
//     setNewStatus(event.target.value);
//   };

//   const handleUpdateStatus = () => {
//     axios.put(`${API_URL}/appointmentsall/${selectedAppointment.id}/status`, { status: newStatus })
//       .then(response => {
//         setSnackbarMessage('Status and points updated successfully');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//         setOpenEditDialog(false);
//         setAppointments(appointments.map(appt => appt.id === selectedAppointment.id ? { ...appt, status: newStatus } : appt));
//       })
//       .catch(error => {
//         setSnackbarMessage('Error updating points');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         console.error('There was an error updating the points!', error);
//       });
//   };
//   const handleDeleteAppointment = () => {
//     axios.delete(`${API_URL}/appointmentsall/${selectedAppointment.id}`)
//       .then(response => {
//         setSnackbarMessage('Appointment deleted successfully');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//         setOpenDeleteDialog(false);
//         setAppointments(appointments.filter(appt => appt.id !== selectedAppointment.id));
//       })
//       .catch(error => {
//         setSnackbarMessage('Error deleting appointment');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         console.error('There was an error deleting the appointment!', error);
//       });
//   };

//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

//   const handleRequestSort = (property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const exportToPDF = () => {
//     const doc = new jsPDF();
//     doc.autoTable({
//       head: [['ID', 'Name', 'Phone', 'Address', 'Birth Date', 'Service', 'Time', 'Date', 'People Count', 'Status']],
//       body: appointments.map(appt => [
//         appt.id,
//         appt.name,
//         appt.phone,
//         appt.address,
//         moment(appt.birth_date).format('DD-MM-YYYY'),
//         appt.service_name,
//         moment(appt.time, 'HH:mm:ss').format('HH:mm'),
//         moment(appt.date).format('DD-MM-YYYY'),
//         appt.people_count,
//         appt.status
//       ])
//     });
//     doc.save('appointments.pdf');
//   };

//   const exportToExcel = () => {
//     const ws = XLSX.utils.json_to_sheet(appointments);
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Appointments');
//     XLSX.writeFile(wb, 'appointments.xlsx');
//   };

//   const filteredAppointments = appointments.filter(appointment =>
//     appointment.name.toLowerCase().includes(filter.toLowerCase()) ||
//     appointment.phone.toLowerCase().includes(filter.toLowerCase())
//   );

//   const sortedAppointments = filteredAppointments.sort((a, b) => {
//     if (orderBy === 'birth_date' || orderBy === 'date' || orderBy === 'time') {
//       return order === 'asc'
//         ? new Date(a[orderBy]) - new Date(b[orderBy])
//         : new Date(b[orderBy]) - new Date(a[orderBy]);
//     } else {
//       return order === 'asc'
//         ? a[orderBy].localeCompare(b[orderBy])
//         : b[orderBy].localeCompare(a[orderBy]);
//     }
//   });

//   return (
//     <Paper style={{ width: '100%' }}>
//       <Box sx={{ mt: 10, mr: 5, ml: 5 }}>
//         <Typography variant="h5">Quản lý lịch khám bệnh</Typography>
//       </Box>
//       <Box display="flex" justifyContent="flex-end" p={2}>
//         <TextField
//           label="Tìm kiếm "
//           variant="outlined"
//           value={filter}
//           onChange={handleFilterChange}
//         />
//       </Box>
//       <Box display="flex" justifyContent="flex-end" p={2}>
//         {/* <Button onClick={exportToPDF} style={{ marginRight: '10px', backgroundColor: '#ff5252', color: '#fff' }}>
//           PDF
//         </Button> */}
//         <Button onClick={exportToExcel} style={{ marginRight: '10px', backgroundColor: '#4caf50', color: '#fff' }}>
//           Excel
//         </Button>
//         <CSVLink data={appointments} filename="appointments.csv">
//           <Button style={{ marginRight: '10px', backgroundColor: '#ff9800', color: '#fff' }}>
//             CSV
//           </Button>
//         </CSVLink>
//       </Box>
//       <Box sx={{ mr: 5, ml: 5 }}>
//         <TableContainer sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
//           <Table sx={{ tableLayout: 'fixed' }}>
//             <TableHead>
//               <TableRow>
//                 <TableCell sx={{ width: '3%' }} sortDirection={orderBy === 'id' ? order : false}>
//                   <TableSortLabel
//                     active={orderBy === 'id'}
//                     direction={orderBy === 'id' ? order : 'asc'}
//                     onClick={() => handleRequestSort('id')}
//                   >
//                     ID
//                   </TableSortLabel>
//                 </TableCell>
//                 <TableCell sx={{ width: '15%' }} sortDirection={orderBy === 'name' ? order : false}>
//                   <TableSortLabel
//                     active={orderBy === 'name'}
//                     direction={orderBy === 'name' ? order : 'asc'}
//                     onClick={() => handleRequestSort('name')}
//                   >
//                     Họ và tên
//                   </TableSortLabel>
//                 </TableCell>
//                 <TableCell sx={{ width: '10%' }} sortDirection={orderBy === 'phone' ? order : false}>
//                   <TableSortLabel
//                     active={orderBy === 'phone'}
//                     direction={orderBy === 'phone' ? order : 'asc'}
//                     onClick={() => handleRequestSort('phone')}
//                   >
//                     Số điện thoại
//                   </TableSortLabel>
//                 </TableCell>
//                 <TableCell sx={{ width: '20%' }} sortDirection={orderBy === 'address' ? order : false}>
//                   <TableSortLabel
//                     active={orderBy === 'address'}
//                     direction={orderBy === 'address' ? order : 'asc'}
//                     onClick={() => handleRequestSort('address')}
//                   >
//                     Địa chỉ
//                   </TableSortLabel>
//                 </TableCell>
//                 <TableCell sx={{ width: '10%' }} sortDirection={orderBy === 'birth_date' ? order : false}>
//                   <TableSortLabel
//                     active={orderBy === 'birth_date'}
//                     direction={orderBy === 'birth_date' ? order : 'asc'}
//                     onClick={() => handleRequestSort('birth_date')}
//                   >
//                     Ngày sinh
//                   </TableSortLabel>
//                 </TableCell>
//                 <TableCell sx={{ width: '15%' }}>Dịch vụ</TableCell>
//                 <TableCell sx={{ width: '3%' }} sortDirection={orderBy === 'time' ? order : false}>
//                   <TableSortLabel
//                     active={orderBy === 'time'}
//                     direction={orderBy === 'time' ? order : 'asc'}
//                     onClick={() => handleRequestSort('time')}
//                   >
//                     Giờ
//                   </TableSortLabel>
//                 </TableCell>
//                 <TableCell sx={{ width: '8%' }} sortDirection={orderBy === 'date' ? order : false}>
//                   <TableSortLabel
//                     active={orderBy === 'date'}
//                     direction={orderBy === 'date' ? order : 'asc'}
//                     onClick={() => handleRequestSort('date')}
//                   >
//                     Ngày
//                   </TableSortLabel>
//                 </TableCell>
//                 <TableCell sx={{ width: '5%' }}>SL</TableCell>
//                 <TableCell sx={{ width: '7%' }}>Trạng thái</TableCell>
//                 <TableCell sx={{ width: '9%' }}>Hành động</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {sortedAppointments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((appointment) => (
//                 <TableRow key={appointment.id}>
//                   <TableCell>{appointment.id}</TableCell>
//                   <TableCell>
//                     <Box display="flex" alignItems="center">
//                       <Avatar alt={appointment.name} src={appointment.avatar} sx={{ marginRight: 2 }} />
//                       {appointment.name}
//                     </Box>
//                   </TableCell>
//                   <TableCell>{appointment.phone}</TableCell>
//                   <TableCell>{appointment.address}</TableCell>
//                   <TableCell>{moment(appointment.birth_date).format('DD-MM-YYYY')}</TableCell>
//                   <TableCell>{appointment.service_name}</TableCell>
//                   <TableCell>{moment(appointment.time, 'HH:mm:ss').format('HH:mm')}</TableCell>
//                   <TableCell>{moment(appointment.date).format('DD-MM-YYYY')}</TableCell>
//                   <TableCell>{appointment.people_count}</TableCell>
//                   <TableCell>{renderStatus(appointment.status)}</TableCell>
//                   <TableCell>
//                     <IconButton
//                       color="primary"
//                       onClick={() => handleEditClick(appointment)}
//                       sx={{
//                         border: '1px solid #1976d2',
//                         borderRadius: '4px',
//                         backgroundColor: '#1976d2',
//                         color: '#ffffff',
//                         marginRight: '8px',
//                         '&:hover': {
//                           backgroundColor: '#1565c0',
//                           borderColor: '#1565c0',
//                         },
//                       }}
//                     >
//                       <EditIcon />
//                     </IconButton>
//                     <IconButton
//                       color="secondary"
//                       onClick={() => handleDeleteClick(appointment)}
//                       sx={{
//                         border: '1px solid #d32f2f',
//                         borderRadius: '4px',
//                         backgroundColor: '#d32f2f',
//                         color: '#ffffff',
//                         '&:hover': {
//                           backgroundColor: '#c62828',
//                           borderColor: '#c62828',
//                         },
//                       }}
//                     >
//                       <DeleteIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <TablePagination
//           rowsPerPageOptions={[5, 8, 10, 25]}
//           component="div"
//           count={filteredAppointments.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Box>
//       <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
//         <DialogTitle>Thay đổi trạng thái khám bệnh</DialogTitle>
//         <DialogContent>
//           <Select value={newStatus} onChange={handleStatusChange}>
//             <MenuItem value="pending">Pending</MenuItem>
//             <MenuItem value="active">Active</MenuItem>
//           </Select>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleUpdateStatus} color="primary">Lưu</Button>
//           <Button onClick={() => setOpenEditDialog(false)} color="secondary">Hủy</Button>
//         </DialogActions>
//       </Dialog>
//       <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
//         <DialogTitle>Xác nhận xóa</DialogTitle>
//         <DialogContent>
//           <Typography>Bạn có chắc chắn muốn xóa cuộc hẹn này không</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleDeleteAppointment} color="primary">Xóa</Button>
//           <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">Hủy</Button>
//         </DialogActions>
//       </Dialog>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//       >
//         <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} elevation={6} variant="filled">
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </Paper>
//   );
// };

// export default AppointmentsTable;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip, TablePagination, TextField, Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, IconButton, Avatar, TableSortLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert from '@mui/material/Alert';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import DescriptionIcon from '@mui/icons-material/Description';
import TableViewIcon from '@mui/icons-material/TableView';

const AppointmentsTable = () => {
  const [appointments, setAppointments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [filter, setFilter] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(`${API_URL}/appointmentsall`)
      .then(response => {
        setAppointments(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the appointments!', error);
      });
  }, [API_URL]);

  if (!Array.isArray(appointments)) {
    return <Typography variant="h6" color="error">Dữ liệu nhận từ API không hợp lệ</Typography>;
  }

  const renderStatus = (status) => {
    let color;
    switch (status) {
      case 'pending':
        color = 'warning';
        break;
      case 'active':
        color = 'success';
        break;
      default:
        color = 'default';
    }
    return <Chip label={status} color={color} />;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleEditClick = (appointment) => {
    setSelectedAppointment(appointment);
    setNewStatus(appointment.status);
    setOpenEditDialog(true);
  };

  const handleDeleteClick = (appointment) => {
    setSelectedAppointment(appointment);
    setOpenDeleteDialog(true);
  };

  const handleStatusChange = (event) => {
    setNewStatus(event.target.value);
  };

  const handleUpdateStatus = () => {
    axios.put(`${API_URL}/appointmentsall/${selectedAppointment.id}/status`, { status: newStatus })
      .then(response => {
        setSnackbarMessage('Status and points updated successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setOpenEditDialog(false);
        setAppointments(appointments.map(appt => appt.id === selectedAppointment.id ? { ...appt, status: newStatus } : appt));
      })
      .catch(error => {
        setSnackbarMessage('Error updating points');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        console.error('There was an error updating the points!', error);
      });
  };

  const handleDeleteAppointment = () => {
    axios.delete(`${API_URL}/appointmentsall/${selectedAppointment.id}`)
      .then(response => {
        setSnackbarMessage('Appointment deleted successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setOpenDeleteDialog(false);
        setAppointments(appointments.filter(appt => appt.id !== selectedAppointment.id));
      })
      .catch(error => {
        setSnackbarMessage('Error deleting appointment');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        console.error('There was an error deleting the appointment!', error);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(appointments);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Appointments');
    XLSX.writeFile(wb, 'appointments.xlsx');
  };

  const filteredAppointments = appointments.filter(appointment =>
    appointment.name.toLowerCase().includes(filter.toLowerCase()) ||
    appointment.phone.toLowerCase().includes(filter.toLowerCase())
  );

  const sortedAppointments = filteredAppointments.sort((a, b) => {
    if (orderBy === 'birth_date' || orderBy === 'date' || orderBy === 'time') {
      return order === 'asc'
        ? new Date(a[orderBy]) - new Date(b[orderBy])
        : new Date(b[orderBy]) - new Date(a[orderBy]);
    } else {
      return order === 'asc'
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    }
  });

  return (
    <Paper style={{ width: '100%' }}>
      <Box sx={{ mt: 10, mr: 5, ml: 5 }}>
        <Typography variant="h5">Quản lý lịch khám bệnh</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <TextField
          label="Tìm kiếm "
          variant="outlined"
          value={filter}
          onChange={handleFilterChange}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button onClick={exportToExcel} style={{ marginRight: '10px', backgroundColor: '#4caf50', color: '#fff' }}>
          <TableViewIcon />
          Excel
        </Button>
        <CSVLink data={appointments} filename="appointments.csv">
          <Button style={{ marginRight: '10px', backgroundColor: '#ff9800', color: '#fff' }}>
            <DescriptionIcon />
            CSV
          </Button>
        </CSVLink>
      </Box>
      <Box sx={{ mr: 5, ml: 5 }}>
        <TableContainer sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
          <Table sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '3%' }} sortDirection={orderBy === 'id' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'id'}
                    direction={orderBy === 'id' ? order : 'asc'}
                    onClick={() => handleRequestSort('id')}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '15%' }} sortDirection={orderBy === 'name' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                  >
                    Họ và tên
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '10%' }} sortDirection={orderBy === 'phone' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'phone'}
                    direction={orderBy === 'phone' ? order : 'asc'}
                    onClick={() => handleRequestSort('phone')}
                  >
                    Số điện thoại
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '20%' }} sortDirection={orderBy === 'address' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'address'}
                    direction={orderBy === 'address' ? order : 'asc'}
                    onClick={() => handleRequestSort('address')}
                  >
                    Địa chỉ
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '10%' }} sortDirection={orderBy === 'birth_date' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'birth_date'}
                    direction={orderBy === 'birth_date' ? order : 'asc'}
                    onClick={() => handleRequestSort('birth_date')}
                  >
                    Ngày sinh
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '15%' }}>Dịch vụ</TableCell>
                <TableCell sx={{ width: '3%' }} sortDirection={orderBy === 'time' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'time'}
                    direction={orderBy === 'time' ? order : 'asc'}
                    onClick={() => handleRequestSort('time')}
                  >
                    Giờ
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '8%' }} sortDirection={orderBy === 'date' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'date'}
                    direction={orderBy === 'date' ? order : 'asc'}
                    onClick={() => handleRequestSort('date')}
                  >
                    Ngày
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '5%' }}>SL</TableCell>
                <TableCell sx={{ width: '7%' }}>Trạng thái</TableCell>
                <TableCell sx={{ width: '9%' }}>Hành động</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAppointments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((appointment) => (
                <TableRow key={appointment.id}>
                  <TableCell>{appointment.id}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Avatar alt={appointment.name} src={appointment.avatar} sx={{ marginRight: 2 }} />
                      {appointment.name}
                    </Box>
                  </TableCell>
                  <TableCell>{appointment.phone}</TableCell>
                  <TableCell>{appointment.address}</TableCell>
                  <TableCell>{moment(appointment.birth_date).format('DD-MM-YYYY')}</TableCell>
                  <TableCell>{appointment.service_name}</TableCell>
                  <TableCell>{moment(appointment.time, 'HH:mm:ss').format('HH:mm')}</TableCell>
                  <TableCell>{moment(appointment.date).format('DD-MM-YYYY')}</TableCell>
                  <TableCell>{appointment.people_count}</TableCell>
                  <TableCell>{renderStatus(appointment.status)}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEditClick(appointment)}
                      sx={{
                        border: '1px solid #1976d2',
                        borderRadius: '4px',
                        backgroundColor: '#1976d2',
                        color: '#ffffff',
                        marginRight: '8px',
                        '&:hover': {
                          backgroundColor: '#1565c0',
                          borderColor: '#1565c0',
                        },
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteClick(appointment)}
                      sx={{
                        border: '1px solid #d32f2f',
                        borderRadius: '4px',
                        backgroundColor: '#d32f2f',
                        color: '#ffffff',
                        '&:hover': {
                          backgroundColor: '#c62828',
                          borderColor: '#c62828',
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 8, 10, 25]}
          component="div"
          count={filteredAppointments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Thay đổi trạng thái khám bệnh</DialogTitle>
        <DialogContent>
          <Select value={newStatus} onChange={handleStatusChange}>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="active">Active</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateStatus} color="primary">Lưu</Button>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">Hủy</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Xác nhận xóa</DialogTitle>
        <DialogContent>
          <Typography>Bạn có chắc chắn muốn xóa cuộc hẹn này không</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteAppointment} color="primary">Xóa</Button>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">Hủy</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} elevation={6} variant="filled">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Paper>
  );
};

export default AppointmentsTable;

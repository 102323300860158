
// // import React from 'react';
// // import { List, ListItem, ListItemText, Drawer, ListItemIcon, Typography, Divider } from '@mui/material';
// // import { Link } from 'react-router-dom';
// // import DashboardIcon from '@mui/icons-material/Dashboard';
// // import ServicesIcon from '@mui/icons-material/Build';
// // import UsersIcon from '@mui/icons-material/Group';
// // import AppointmentsIcon from '@mui/icons-material/DateRange';
// // import TokensIcon from '@mui/icons-material/VpnKey';
// // import { styled } from '@mui/system';

// // const drawerWidth = 240;

// // const StyledDrawer = styled(Drawer)(({ theme }) => ({
// //   width: drawerWidth,
// //   flexShrink: 0,
// //   '& .MuiDrawer-paper': {
// //     width: drawerWidth,
// //     boxSizing: 'border-box',
// //     marginTop: '64px',
// //     backgroundColor: '#282c34', // Background color for the sidebar
// //     color: '#fff', // Text color for the sidebar
// //   },
// // }));

// // const StyledListItem = styled(ListItem)(({ theme }) => ({
// //   '&:hover': {
// //     backgroundColor: '#3a3f47', // Background color on hover
// //   },
// //   '& .MuiListItemIcon-root': {
// //     color: '#fff', // Icon color
// //   },
// //   '& .MuiListItemText-primary': {
// //     color: '#fff', // Text color
// //   },
// // }));

// // const Sidebar = () => {
// //   return (
// //     <StyledDrawer variant="permanent">
// //       <div style={{ padding: '16px', textAlign: 'center' }}>
// //         <Typography variant="h6" noWrap component="div" color="inherit">
// //           MOTAADMIN
// //         </Typography>
// //       </div>
// //       <Divider />
// //       <List>
// //         <StyledListItem button component={Link} to="/dashboard">
// //           <ListItemIcon><DashboardIcon /></ListItemIcon>
// //           <ListItemText primary="Dashboard" />
// //         </StyledListItem>
// //         <StyledListItem button component={Link} to="/services">
// //           <ListItemIcon><ServicesIcon /></ListItemIcon>
// //           <ListItemText primary="Services" />
// //         </StyledListItem>
// //         <StyledListItem button component={Link} to="/users">
// //           <ListItemIcon><UsersIcon /></ListItemIcon>
// //           <ListItemText primary="Users" />
// //         </StyledListItem>
// //         <StyledListItem button component={Link} to="/appointments">
// //           <ListItemIcon><AppointmentsIcon /></ListItemIcon>
// //           <ListItemText primary="Quản lý đặt lịch" />
// //         </StyledListItem>
// //         <StyledListItem button component={Link} to="/update-tokens">
// //           <ListItemIcon><TokensIcon /></ListItemIcon>
// //           <ListItemText primary="Quản lý Token" />
// //         </StyledListItem>
// //       </List>
// //     </StyledDrawer>
// //   );
// // };

// // export default Sidebar;
// import React from 'react';
// import { List, ListItem, ListItemText, Drawer, ListItemIcon, Typography, Divider } from '@mui/material';
// import { Link } from 'react-router-dom';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import ServicesIcon from '@mui/icons-material/Build';
// import UsersIcon from '@mui/icons-material/Group';
// import AppointmentsIcon from '@mui/icons-material/DateRange';
// import TokensIcon from '@mui/icons-material/VpnKey';
// import { styled } from '@mui/system';

// const drawerWidth = 240;

// const StyledDrawer = styled(Drawer)(({ theme }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   '& .MuiDrawer-paper': {
//     width: drawerWidth,
//     boxSizing: 'border-box',
//     backgroundColor: '#282c34', // Background color for the sidebar
//     color: '#fff', // Text color for the sidebar
//   },
// }));

// const StyledListItem = styled(ListItem)(({ theme }) => ({
//   '&:hover': {
//     backgroundColor: '#3a3f47', // Background color on hover
//   },
//   '& .MuiListItemIcon-root': {
//     color: '#fff', // Icon color
//   },
//   '& .MuiListItemText-primary': {
//     color: '#fff', // Text color
//   },
// }));

// const Sidebar = () => {
//   return (
//     <StyledDrawer variant="permanent">
//       <div style={{ padding: '16px', textAlign: 'center' }}>
//         <Typography variant="h6" noWrap component="div" color="inherit">
//           SẢN PHỤ KHOA
//         </Typography>
//       </div>
//       <Divider />
//       <List>
//         <StyledListItem button component={Link} to="/dashboard">
//           <ListItemIcon><DashboardIcon /></ListItemIcon>
//           <ListItemText primary="Tổng quan" />
//         </StyledListItem>
//         <StyledListItem button component={Link} to="/appointments">
//           <ListItemIcon><AppointmentsIcon /></ListItemIcon>
//           <ListItemText primary="Quản lý đặt lịch" />
//         </StyledListItem>
//         <StyledListItem button component={Link} to="/services">
//           <ListItemIcon><ServicesIcon /></ListItemIcon>
//           <ListItemText primary="Dịch vụ" />
//         </StyledListItem>
      
//         <StyledListItem button component={Link} to="/users">
//           <ListItemIcon><UsersIcon /></ListItemIcon>
//           <ListItemText primary="Khách hàng" />
//         </StyledListItem>
//         <StyledListItem button component={Link} to="/tintuc">
//           <ListItemIcon><UsersIcon /></ListItemIcon>
//           <ListItemText primary="Tin tức" />
//         </StyledListItem>
        
        
//         <StyledListItem button component={Link} to="/update-tokens">
//           <ListItemIcon><TokensIcon /></ListItemIcon>
//           <ListItemText primary="Quản lý Token" />
//         </StyledListItem>
         
//         <StyledListItem button component={Link} to="/taikhoan">
//           <ListItemIcon><TokensIcon /></ListItemIcon>
//           <ListItemText primary="Tạo tài khoản" />
//         </StyledListItem>
        
//       </List>
//     </StyledDrawer>
//   );
// };

// export default Sidebar;
import React from 'react';
import { List, ListItem, ListItemText, Drawer, ListItemIcon, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ServicesIcon from '@mui/icons-material/Build';
import UsersIcon from '@mui/icons-material/Group';
import AppointmentsIcon from '@mui/icons-material/DateRange';
import TokensIcon from '@mui/icons-material/VpnKey';
import NewsIcon from '@mui/icons-material/Article'; // Icon for News
import AccountIcon from '@mui/icons-material/AccountCircle'; // Icon for Create Account
import { styled } from '@mui/system';

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: '#282c34', // Background color for the sidebar
    color: '#fff', // Text color for the sidebar
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#3a3f47', // Background color on hover
  },
  '& .MuiListItemIcon-root': {
    color: '#fff', // Icon color
  },
  '& .MuiListItemText-primary': {
    color: '#fff', // Text color
  },
}));

const Sidebar = () => {
  return (
    <StyledDrawer variant="permanent">
      <div style={{ padding: '16px', textAlign: 'center' }}>
        <Typography variant="h6" noWrap component="div" color="inherit">
          SẢN PHỤ KHOA
        </Typography>
      </div>
      <Divider />
      <List>
        <StyledListItem button component={Link} to="/dashboard">
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary="Tổng quan" />
        </StyledListItem>
        <StyledListItem button component={Link} to="/appointments">
          <ListItemIcon><AppointmentsIcon /></ListItemIcon>
          <ListItemText primary="Quản lý đặt lịch" />
        </StyledListItem>
        <StyledListItem button component={Link} to="/services">
          <ListItemIcon><ServicesIcon /></ListItemIcon>
          <ListItemText primary="Dịch vụ" />
        </StyledListItem>
        <StyledListItem button component={Link} to="/users">
          <ListItemIcon><UsersIcon /></ListItemIcon>
          <ListItemText primary="Khách hàng" />
        </StyledListItem>
        <StyledListItem button component={Link} to="/tintuc">
          <ListItemIcon><NewsIcon /></ListItemIcon>
          <ListItemText primary="Tin tức" />
        </StyledListItem>
        <StyledListItem button component={Link} to="/update-tokens">
          <ListItemIcon><TokensIcon /></ListItemIcon>
          <ListItemText primary="Quản lý Token" />
        </StyledListItem>
        <StyledListItem button component={Link} to="/taikhoan">
          <ListItemIcon><AccountIcon /></ListItemIcon>
          <ListItemText primary="Tạo tài khoản" />
        </StyledListItem>
      </List>
    </StyledDrawer>
  );
};

export default Sidebar;

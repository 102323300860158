
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
//   IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button,
//   TextField, Snackbar, Avatar, Box, Typography
// } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { useDropzone } from 'react-dropzone';
// import MuiAlert from '@mui/material/Alert';

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// const ServicesTable = () => {
//   const [services, setServices] = useState([]);
//   const [editService, setEditService] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [serviceToDelete, setServiceToDelete] = useState(null);
//   const API_URL = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     axios.get(`${API_URL}/services`)
//       .then(response => {
//         setServices(response.data);
//       })
//       .catch(error => {
//         console.error('There was an error fetching the services!', error);
//       });
//   }, [API_URL]);

//   const handleEdit = (service) => {
//     setEditService(service);
//     setOpen(true);
//   };

//   const handleCreate = () => {
//     setEditService({ name: '', description: '', price_range: '', points: '', image: '' });
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setEditService(null);
//     setSelectedFile(null);
//   };

//   const handleSave = () => {
//     const formData = new FormData();
//     formData.append('name', editService.name);
//     formData.append('description', editService.description);
//     formData.append('price_range', editService.price_range);
//     formData.append('points', editService.points);
//     if (selectedFile) {
//       formData.append('image', selectedFile);
//     }

//     const request = editService.id
//       ? axios.put(`${API_URL}/services/${editService.id}`, formData)
//       : axios.post(`${API_URL}/services`, formData);

//     request
//       .then((response) => {
//         if (editService.id) {
//           const updatedService = { ...editService, image: response.data.imageUrl ? response.data.imageUrl.split('/').pop() : editService.image };
//           setServices(services.map(service =>
//             service.id === editService.id ? updatedService : service
//           ));
//         } else {
//           setServices([...services, { ...response.data, image: response.data.imageUrl ? response.data.imageUrl.split('/').pop() : '' }]);
//         }
//         handleClose();
//         setSnackbarMessage('Bạn đã lưu dữ liệu thành công');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       })
//       .catch(error => {
//         console.error('There was an error saving the service!', error);
//         setSnackbarMessage('Có lỗi xảy ra khi lưu dữ liệu');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       });
//   };

//   const handleDelete = (serviceId) => {
//     setServiceToDelete(serviceId);
//     setOpenDeleteDialog(true);
//   };

//   const handleConfirmDelete = () => {
//     axios.delete(`${API_URL}/services/${serviceToDelete}`)
//       .then(() => {
//         setServices(services.filter(service => service.id !== serviceToDelete));
//         setSnackbarMessage('Dịch vụ đã được xóa');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//         setOpenDeleteDialog(false);
//       })
//       .catch(error => {
//         console.error('There was an error deleting the service!', error);
//         setSnackbarMessage('Có lỗi xảy ra khi xóa dịch vụ');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       });
//   };

//   const handleChange = (e) => {
//     setEditService({ ...editService, [e.target.name]: e.target.value });
//   };

//   const handleEditorChange = (event, editor) => {
//     const data = editor.getData();
//     setEditService({ ...editService, description: data });
//   };

//   const onDrop = (acceptedFiles) => {
//     setSelectedFile(acceptedFiles[0]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop });

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <TableContainer component={Paper}>
//       <Box sx={{ mt: 3, mr: 5, ml: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//         <Typography variant="h5">Quản lý dịch vụ</Typography>
//         <Button
//           variant="contained"
//           color="primary"
//           startIcon={<AddIcon />}
//           onClick={handleCreate}
//           sx={{ mb: 2 }}
//         >
//           Tạo mới dịch vụ
//         </Button>
//       </Box>
//       <Box sx={{ mr: 5, ml: 5 }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>ID</TableCell>
//               <TableCell>Tên dịch vụ</TableCell>
//               <TableCell>Khoảng giá</TableCell>
//               <TableCell>Điểm thưởng</TableCell>
//               <TableCell>Hành động</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {services.map((service) => (
//               <TableRow key={service.id}>
//                 <TableCell>{service.id}</TableCell>
//                 <TableCell>{service.name}</TableCell>
//                 <TableCell>{service.price_range}</TableCell>
//                 <TableCell>{service.points}</TableCell>
//                 <TableCell>
//                   <IconButton
//                     color="primary"
//                     onClick={() => handleEdit(service)}
//                     sx={{
//                       border: '1px solid #1976d2',
//                       borderRadius: '4px',
//                       backgroundColor: '#1976d2',
//                       color: '#ffffff',
//                       marginRight: '8px',
//                       '&:hover': {
//                         backgroundColor: '#1565c0',
//                         borderColor: '#1565c0',
//                       },
//                     }}
//                   >
//                     <EditIcon />
//                   </IconButton>
//                   <IconButton
//                     color="secondary"
//                     onClick={() => handleDelete(service.id)}
//                     sx={{
//                       border: '1px solid #d32f2f',
//                       borderRadius: '4px',
//                       backgroundColor: '#d32f2f',
//                       color: '#ffffff',
//                       '&:hover': {
//                         backgroundColor: '#c62828',
//                         borderColor: '#c62828',
//                       },
//                     }}
//                   >
//                     <DeleteIcon />
//                   </IconButton>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </Box>
//       <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
//         <DialogTitle>{editService?.id ? 'Sửa dịch vụ' : 'Tạo mới dịch vụ'}</DialogTitle>
//         <DialogContent>
//           <TextField
//             margin="dense"
//             label="Tên dịch vụ"
//             type="text"
//             fullWidth
//             name="name"
//             value={editService?.name || ''}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             label="Khoảng giá"
//             type="text"
//             fullWidth
//             name="price_range"
//             value={editService?.price_range || ''}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             label="Điểm thưởng"
//             type="text"
//             fullWidth
//             name="points"
//             value={editService?.points || ''}
//             onChange={handleChange}
//           />
//           {editService?.image && (
//             <div>
//               <img src={`${API_URL}/uploads/${editService.image}`} alt="thumbnail" style={{ width: '300px', height: '180px' }} />
//             </div>
//           )}
//           <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center', marginTop: '10px' }}>
//             <input {...getInputProps()} />
//             <p>Chọn tệp hoặc kéo thả tệp vào đây</p>
//             {selectedFile && <p>{selectedFile.name}</p>}
//           </div>
//           <CKEditor
//             editor={ClassicEditor}
//             data={editService?.description || ''}
//             onChange={handleEditorChange}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="secondary" variant="outlined">
//             Hủy
//           </Button>
//           <Button onClick={handleSave} color="primary" variant="contained">
//             Lưu
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
//         <DialogTitle>Xác nhận xóa</DialogTitle>
//         <DialogContent>
//           <Typography>Bạn có chắc chắn muốn xóa dịch vụ này không?</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDeleteDialog(false)} color="secondary" variant="outlined">
//             Hủy
//           </Button>
//           <Button onClick={handleConfirmDelete} color="primary" variant="contained">
//             Xóa
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//       >
//         <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </TableContainer>
//   );
// };

// export default ServicesTable;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button,
  TextField, Snackbar, Avatar, Box, Typography, TablePagination
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDropzone } from 'react-dropzone';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ServicesTable = () => {
  const [services, setServices] = useState([]);
  const [editService, setEditService] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(`${API_URL}/services`)
      .then(response => {
        setServices(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the services!', error);
      });
  }, [API_URL]);

  const handleEdit = (service) => {
    setEditService(service);
    setOpen(true);
  };

  const handleCreate = () => {
    setEditService({ name: '', description: '', price_range: '', points: '', image: '' });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditService(null);
    setSelectedFile(null);
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append('name', editService.name);
    formData.append('description', editService.description);
    formData.append('price_range', editService.price_range);
    formData.append('points', editService.points);
    if (selectedFile) {
      formData.append('image', selectedFile);
    }

    const request = editService.id
      ? axios.put(`${API_URL}/services/${editService.id}`, formData)
      : axios.post(`${API_URL}/services`, formData);

    request
      .then((response) => {
        if (editService.id) {
          const updatedService = { ...editService, image: response.data.imageUrl ? response.data.imageUrl.split('/').pop() : editService.image };
          setServices(services.map(service =>
            service.id === editService.id ? updatedService : service
          ));
        } else {
          setServices([...services, { ...response.data, image: response.data.imageUrl ? response.data.imageUrl.split('/').pop() : '' }]);
        }
        handleClose();
        setSnackbarMessage('Bạn đã lưu dữ liệu thành công');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(error => {
        console.error('There was an error saving the service!', error);
        setSnackbarMessage('Có lỗi xảy ra khi lưu dữ liệu');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleDelete = (serviceId) => {
    setServiceToDelete(serviceId);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = () => {
    axios.delete(`${API_URL}/services/${serviceToDelete}`)
      .then(() => {
        setServices(services.filter(service => service.id !== serviceToDelete));
        setSnackbarMessage('Dịch vụ đã được xóa');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setOpenDeleteDialog(false);
      })
      .catch(error => {
        console.error('There was an error deleting the service!', error);
        setSnackbarMessage('Có lỗi xảy ra khi xóa dịch vụ');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleChange = (e) => {
    setEditService({ ...editService, [e.target.name]: e.target.value });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditService({ ...editService, description: data });
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Box sx={{ mt: 3, mr: 5, ml: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">Quản lý dịch vụ</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
          sx={{ mb: 2 }}
        >
          Tạo mới dịch vụ
        </Button>
      </Box>
      <Box sx={{ mr: 5, ml: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Tên dịch vụ</TableCell>
              <TableCell>Khoảng giá</TableCell>
              <TableCell>Điểm thưởng</TableCell>
              <TableCell>Hành động</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((service) => (
              <TableRow key={service.id}>
                <TableCell>{service.id}</TableCell>
                <TableCell>{service.name}</TableCell>
                <TableCell>{service.price_range}</TableCell>
                <TableCell>{service.points}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleEdit(service)}
                    sx={{
                      border: '1px solid #1976d2',
                      borderRadius: '4px',
                      backgroundColor: '#1976d2',
                      color: '#ffffff',
                      marginRight: '8px',
                      '&:hover': {
                        backgroundColor: '#1565c0',
                        borderColor: '#1565c0',
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(service.id)}
                    sx={{
                      border: '1px solid #d32f2f',
                      borderRadius: '4px',
                      backgroundColor: '#d32f2f',
                      color: '#ffffff',
                      '&:hover': {
                        backgroundColor: '#c62828',
                        borderColor: '#c62828',
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[8, 16, 24]}
          component="div"
          count={services.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{editService?.id ? 'Sửa dịch vụ' : 'Tạo mới dịch vụ'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Tên dịch vụ"
            type="text"
            fullWidth
            name="name"
            value={editService?.name || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Khoảng giá"
            type="text"
            fullWidth
            name="price_range"
            value={editService?.price_range || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Điểm thưởng"
            type="text"
            fullWidth
            name="points"
            value={editService?.points || ''}
            onChange={handleChange}
          />
          {editService?.image && (
            <div>
              <img src={`${API_URL}/uploads/${editService.image}`} alt="thumbnail" style={{ width: '300px', height: '180px' }} />
            </div>
          )}
          <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center', marginTop: '10px' }}>
            <input {...getInputProps()} />
            <p>Chọn tệp hoặc kéo thả tệp vào đây</p>
            {selectedFile && <p>{selectedFile.name}</p>}
          </div>
          <CKEditor
            editor={ClassicEditor}
            data={editService?.description || ''}
            onChange={handleEditorChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Hủy
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Lưu
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Xác nhận xóa</DialogTitle>
        <DialogContent>
          <Typography>Bạn có chắc chắn muốn xóa dịch vụ này không?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary" variant="outlined">
            Hủy
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" variant="contained">
            Xóa
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
};

export default ServicesTable;


// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Container, Typography, Snackbar } from '@mui/material';
// import axios from 'axios';

// const UpdateTokens = () => {
//   const [accessToken, setAccessToken] = useState('');
//   const [refreshToken, setRefreshToken] = useState('');
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success, error, warning, info
//   const API_URL = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     // Lấy token khi component được render
//     axios.get(`${API_URL}/get-tokens`)
//       .then(response => {
//         console.log('Tokens fetched:', response.data);
//         setAccessToken(response.data.accessToken || '');
//         setRefreshToken(response.data.refreshToken || '');
//       })
//       .catch(error => {
//         console.error('Error fetching tokens:', error);
//       });
//   }, [API_URL]);

//   const handleUpdateTokens = () => {
//     axios.post(`${API_URL}/update-tokens`, { accessToken, refreshToken })
//       .then(response => {
//         setSnackbarMessage(response.data.message);
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//         console.log('Tokens updated successfully');
//       })
//       .catch(error => {
//         console.error('Error updating tokens:', error);
//         setSnackbarMessage('Failed to update tokens');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       });
//   };

//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <Container maxWidth="xs">
//       <Typography variant="h4" gutterBottom>
//         Update Tokens
//       </Typography>
//       <TextField
//         label="Access Token"
//         fullWidth
//         margin="normal"
//         value={accessToken}
//         onChange={(e) => setAccessToken(e.target.value)}
//       />
//       <TextField
//         label="Refresh Token"
//         fullWidth
//         margin="normal"
//         value={refreshToken}
//         onChange={(e) => setRefreshToken(e.target.value)}
//       />
//       <Button
//         variant="contained"
//         color="primary"
//         fullWidth
//         onClick={handleUpdateTokens}
//       >
//         Update
//       </Button>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//         message={snackbarMessage}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//         severity={snackbarSeverity}
//       />
//     </Container>
//   );
// };

// export default UpdateTokens;
// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Container, Typography, Snackbar, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import ErrorIcon from '@mui/icons-material/Error';
// import axios from 'axios';

// const UpdateTokens = () => {
//   const [accessToken, setAccessToken] = useState('');
//   const [refreshToken, setRefreshToken] = useState('');
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success, error, warning, info
//   const API_URL = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     // Lấy token khi component được render
//     axios.get(`${API_URL}/get-tokens`)
//       .then(response => {
//         console.log('Tokens fetched:', response.data);
//         setAccessToken(response.data.accessToken || '');
//         setRefreshToken(response.data.refreshToken || '');
//       })
//       .catch(error => {
//         console.error('Lỗi hiển thị tokens:', error);
//       });
//   }, [API_URL]);

//   const handleUpdateTokens = () => {
//     axios.post(`${API_URL}/update-tokens`, { accessToken, refreshToken })
//       .then(response => {
//         setSnackbarMessage(response.data.message);
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//         console.log('Tokens cập nhật thành công');
//       })
//       .catch(error => {
//         console.error('Error updating tokens:', error);
//         setSnackbarMessage('Lỗi khi cập nhật tokens');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       });
//   };

//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <Container maxWidth="xs">
//       <Typography variant="h5" gutterBottom>
//         Cập nhật Tokent Zalo OA
//       </Typography>
//       <TextField
//         label="Access Token"
//         fullWidth
//         margin="normal"
//         value={accessToken}
//         onChange={(e) => setAccessToken(e.target.value)}
//       />
//       <TextField
//         label="Refresh Token"
//         fullWidth
//         margin="normal"
//         value={refreshToken}
//         onChange={(e) => setRefreshToken(e.target.value)}
//       />
//       <Button
//         variant="contained"
//         color="primary"
//         fullWidth
//         onClick={handleUpdateTokens}
//       >
//         Lưu
//       </Button>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//         message={
//           <span style={{ display: 'flex', alignItems: 'center' }}>
//             {snackbarSeverity === 'success' ? (
//               <CheckCircleIcon style={{ marginRight: '8px', color: 'green' }} />
//             ) : (
//               <ErrorIcon style={{ marginRight: '8px', color: 'red' }} />
//             )}
//             {snackbarMessage}
//           </span>
//         }
//         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//         action={
//           <IconButton size="small" color="inherit" onClick={handleCloseSnackbar}>
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         }
//       />
//     </Container>
//   );
// };

// export default UpdateTokens;
import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import axios from 'axios';

const UpdateTokens = () => {
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success, error, warning, info
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Lấy token khi component được render
    axios.get(`${API_URL}/get-tokens`)
      .then(response => {
        console.log('Tokens fetched:', response.data);
        setAccessToken(response.data.accessToken || '');
        setRefreshToken(response.data.refreshToken || '');
      })
      .catch(error => {
        console.error('Lỗi hiển thị tokens:', error);
      });
  }, [API_URL]);

  const handleUpdateTokens = () => {
    if (!accessToken || !refreshToken) {
      setSnackbarMessage('Vui lòng nhập đầy đủ các trường');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    axios.post(`${API_URL}/update-tokens`, { accessToken, refreshToken })
      .then(response => {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        console.log('Tokens cập nhật thành công');
      })
      .catch(error => {
        console.error('Error updating tokens:', error);
        setSnackbarMessage('Lỗi khi cập nhật tokens');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="xs" style={{ marginTop: '100px', textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Cập nhật Tokent Zalo OA
      </Typography>
      <TextField
        label="Access Token"
        fullWidth
        margin="normal"
        value={accessToken}
        onChange={(e) => setAccessToken(e.target.value)}
        required
      />
      <TextField
        label="Refresh Token"
        fullWidth
        margin="normal"
        value={refreshToken}
        onChange={(e) => setRefreshToken(e.target.value)}
        required
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleUpdateTokens}
        style={{ marginTop: '20px' }}
      >
        Lưu
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {snackbarSeverity === 'success' ? (
              <CheckCircleIcon style={{ marginRight: '8px', color: 'green' }} />
            ) : (
              <ErrorIcon style={{ marginRight: '8px', color: 'red' }} />
            )}
            {snackbarMessage}
          </span>
        }
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default UpdateTokens;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
//   IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button,
//   TextField, Snackbar, Box, Typography
// } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import MuiAlert from '@mui/material/Alert';

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// const AdminTable = () => {
//   const [admins, setAdmins] = useState([]);
//   const [editAdmin, setEditAdmin] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const API_URL = process.env.REACT_APP_API_URL;
//   const token = localStorage.getItem('token');

//   useEffect(() => {
//     const fetchAdmins = async () => {
//       try {
//         const response = await axios.get(`${API_URL}/api/admins`, {
//           headers: {
//             'x-access-token': token,
//             'Content-Type': 'application/json'
//           }
//         });
//         setAdmins(response.data);
//       } catch (error) {
//         console.error('There was an error fetching the admin accounts!', error);
//         setSnackbarMessage('Có lỗi xảy ra khi lấy dữ liệu tài khoản admin');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       }
//     };

//     fetchAdmins();
//   }, [API_URL, token]);

//   const handleEdit = (admin) => {
//     setEditAdmin(admin);
//     setOpen(true);
//   };

//   const handleCreate = () => {
//     setEditAdmin({ username: '', password: '', role: 'admin' });
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setEditAdmin(null);
//   };

//   const handleSave = () => {
//     const adminData = { ...editAdmin };

//     const request = editAdmin.id
//       ? axios.put(`${API_URL}/admin/${editAdmin.id}`, adminData, {
//           headers: {
//             'x-access-token': token,
//             'Content-Type': 'application/json'
//           }
//         })
//       : axios.post(`${API_URL}/admin`, adminData, {
//           headers: {
//             'x-access-token': token,
//             'Content-Type': 'application/json'
//           }
//         });

//     request
//       .then((response) => {
//         const updatedAdmin = {
//           ...editAdmin,
//           id: response.data.id,
//         };
//         setAdmins(editAdmin.id ? admins.map(admin => (admin.id === editAdmin.id ? updatedAdmin : admin)) : [...admins, updatedAdmin]);
//         handleClose();
//         setSnackbarMessage('Bạn đã lưu dữ liệu thành công');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       })
//       .catch(error => {
//         console.error('There was an error updating the admin account!', error);
//         setSnackbarMessage('Có lỗi xảy ra khi lưu dữ liệu');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       });
//   };

//   const handleDelete = (adminId) => {
//     axios.delete(`${API_URL}/admin/${adminId}`, {
//       headers: {
//         'x-access-token': token,
//         'Content-Type': 'application/json'
//       }
//     })
//       .then(() => {
//         setAdmins(admins.filter(admin => admin.id !== adminId));
//         setSnackbarMessage('Tài khoản admin đã được xóa');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       })
//       .catch(error => {
//         console.error('There was an error deleting the admin account!', error);
//         setSnackbarMessage('Có lỗi xảy ra khi xóa tài khoản admin');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       });
//   };

//   const handleChange = (e) => {
//     setEditAdmin({ ...editAdmin, [e.target.name]: e.target.value });
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <Box>
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 10, mr: 5, ml: 5 }}>
//         <Typography variant="h6">Quản lý tài khoản admin</Typography>
//         <Button
//           variant="contained"
//           color="secondary"
//           startIcon={<AddIcon />}
//           onClick={handleCreate}
//           sx={{
//             backgroundColor: '#e91e63',
//             '&:hover': {
//               backgroundColor: '#d81b60',
//             },
//           }}
//         >
//           Tạo tài khoản mới
//         </Button>
//       </Box>
//       <Box sx={{ m: 5 }}>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>ID</TableCell>
//                 <TableCell>Tên người dùng</TableCell>
//                 <TableCell>Vai trò</TableCell>
//                 <TableCell>Ngày tạo</TableCell>
//                 <TableCell>Hành động</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {admins.map((admin) => (
//                 <TableRow key={admin.id}>
//                   <TableCell>{admin.id}</TableCell>
//                   <TableCell>{admin.username}</TableCell>
//                   <TableCell>{admin.role}</TableCell>
//                   <TableCell>{new Date(admin.created_at).toLocaleDateString()}</TableCell>
//                   <TableCell>
//                     <IconButton
//                       color="primary"
//                       onClick={() => handleEdit(admin)}
//                       sx={{
//                         border: '1px solid #1976d2',
//                         borderRadius: '4px',
//                         backgroundColor: '#1976d2',
//                         color: '#ffffff',
//                         marginRight: '8px',
//                         '&:hover': {
//                           backgroundColor: '#1565c0',
//                           borderColor: '#1565c0',
//                         },
//                       }}
//                     >
//                       <EditIcon />
//                     </IconButton>
//                     <IconButton
//                       color="secondary"
//                       onClick={() => handleDelete(admin.id)}
//                       sx={{
//                         border: '1px solid #d32f2f',
//                         borderRadius: '4px',
//                         backgroundColor: '#d32f2f',
//                         color: '#ffffff',
//                         '&:hover': {
//                           backgroundColor: '#c62828',
//                           borderColor: '#c62828',
//                         },
//                       }}
//                     >
//                       <DeleteIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//       <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
//         <DialogTitle>{editAdmin?.id ? 'Sửa tài khoản' : 'Tạo tài khoản mới'}</DialogTitle>
//         <DialogContent>
//           <TextField
//             margin="dense"
//             label="Tên người dùng"
//             type="text"
//             fullWidth
//             name="username"
//             value={editAdmin?.username || ''}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             label="Mật khẩu"
//             type="password"
//             fullWidth
//             name="password"
//             value={editAdmin?.password || ''}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             label="Vai trò"
//             type="text"
//             fullWidth
//             name="role"
//             value={editAdmin?.role || ''}
//             onChange={handleChange}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="secondary" variant="outlined">
//             Hủy
//           </Button>
//           <Button onClick={handleSave} color="primary" variant="contained">
//             Lưu
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//       >
//         <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default AdminTable;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button,
  TextField, Snackbar, Box, Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AdminTable = () => {
  const [admins, setAdmins] = useState([]);
  const [editAdmin, setEditAdmin] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [deleteAdminId, setDeleteAdminId] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/admins`, {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
          }
        });
        setAdmins(response.data);
      } catch (error) {
        console.error('There was an error fetching the admin accounts!', error);
        setSnackbarMessage('Có lỗi xảy ra khi lấy dữ liệu tài khoản admin');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchAdmins();
  }, [API_URL, token]);

  const handleEdit = (admin) => {
    setEditAdmin(admin);
    setOpen(true);
  };

  const handleCreate = () => {
    setEditAdmin({ username: '', password: '', role: 'admin' });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditAdmin(null);
  };

  const handleSave = () => {
    const adminData = { ...editAdmin };

    const request = editAdmin.id
      ? axios.put(`${API_URL}/admin/${editAdmin.id}`, adminData, {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
          }
        })
      : axios.post(`${API_URL}/admin`, adminData, {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
          }
        });

    request
      .then((response) => {
        const updatedAdmin = {
          ...editAdmin,
          id: response.data.id,
        };
        setAdmins(editAdmin.id ? admins.map(admin => (admin.id === editAdmin.id ? updatedAdmin : admin)) : [...admins, updatedAdmin]);
        handleClose();
        setSnackbarMessage('Bạn đã lưu dữ liệu thành công');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(error => {
        console.error('There was an error updating the admin account!', error);
        setSnackbarMessage('Có lỗi xảy ra khi lưu dữ liệu');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleDelete = (adminId) => {
    setDeleteAdminId(adminId);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    axios.delete(`${API_URL}/admin/${deleteAdminId}`, {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    })
      .then(() => {
        setAdmins(admins.filter(admin => admin.id !== deleteAdminId));
        setSnackbarMessage('Tài khoản admin đã được xóa');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setDeleteDialogOpen(false);
      })
      .catch(error => {
        console.error('There was an error deleting the admin account!', error);
        setSnackbarMessage('Có lỗi xảy ra khi xóa tài khoản admin');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setDeleteDialogOpen(false);
      });
  };

  const handleChange = (e) => {
    setEditAdmin({ ...editAdmin, [e.target.name]: e.target.value });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 10, mr: 5, ml: 5 }}>
        <Typography variant="h6">Quản lý tài khoản admin</Typography>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
          sx={{
            backgroundColor: '#e91e63',
            '&:hover': {
              backgroundColor: '#d81b60',
            },
          }}
        >
          Tạo tài khoản mới
        </Button>
      </Box>
      <Box sx={{ m: 5 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Tên người dùng</TableCell>
                <TableCell>Vai trò</TableCell>
                <TableCell>Ngày tạo</TableCell>
                <TableCell>Hành động</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admins.map((admin) => (
                <TableRow key={admin.id}>
                  <TableCell>{admin.id}</TableCell>
                  <TableCell>{admin.username}</TableCell>
                  <TableCell>{admin.role}</TableCell>
                  <TableCell>{new Date(admin.created_at).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(admin)}
                      sx={{
                        border: '1px solid #1976d2',
                        borderRadius: '4px',
                        backgroundColor: '#1976d2',
                        color: '#ffffff',
                        marginRight: '8px',
                        '&:hover': {
                          backgroundColor: '#1565c0',
                          borderColor: '#1565c0',
                        },
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(admin.id)}
                      sx={{
                        border: '1px solid #d32f2f',
                        borderRadius: '4px',
                        backgroundColor: '#d32f2f',
                        color: '#ffffff',
                        '&:hover': {
                          backgroundColor: '#c62828',
                          borderColor: '#c62828',
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{editAdmin?.id ? 'Sửa tài khoản' : 'Tạo tài khoản mới'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Tên người dùng"
            type="text"
            fullWidth
            name="username"
            value={editAdmin?.username || ''}
            onChange={handleChange}
          />
          {editAdmin?.id ? (
            <>
              <TextField
                margin="dense"
                label="Mật khẩu cũ"
                type="password"
                fullWidth
                name="oldPassword"
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                label="Mật khẩu mới"
                type="password"
                fullWidth
                name="password"
                onChange={handleChange}
              />
            </>
          ) : (
            <TextField
              margin="dense"
              label="Mật khẩu"
              type="password"
              fullWidth
              name="password"
              value={editAdmin?.password || ''}
              onChange={handleChange}
            />
          )}
          <TextField
            margin="dense"
            label="Vai trò"
            type="text"
            fullWidth
            name="role"
            value={editAdmin?.role || ''}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Hủy
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Lưu
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Xác nhận xóa</DialogTitle>
        <DialogContent>Bạn có chắc chắn muốn xóa tài khoản này không?</DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="secondary" variant="outlined">
            Hủy
          </Button>
          <Button onClick={confirmDelete} color="primary" variant="contained">
            Xóa
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminTable;
